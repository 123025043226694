import writeToLocalstorage from "./writeToLocalstorage";
export default function getContextDataFromLsOrApi(localStorageKey, getDataFromApi, getDataFromLocalStorage) {
    if (getDataFromLocalStorage) {
        const dataLs = getDataFromLocalStorage(localStorageKey);
        if (dataLs)
            return Promise.resolve(dataLs);
    }
    return getDataFromApi().then((dataRest) => {
        if (dataRest) {
            writeToLocalstorage(localStorageKey, dataRest);
            return dataRest;
        }
        throw new Error("Received undefined data from REST API for context.");
    });
}
