import { EProductEdition } from "../../../../enums";
export default function getProductEditionByApiString(edition) {
    switch (edition) {
        case "BASIC":
            return EProductEdition.BASIC;
        case "PROFESSIONAL":
            return EProductEdition.PROFESSIONAL;
        case "ENTERPRISE":
            return EProductEdition.ENTERPRISE;
        case "SERVICE":
            return EProductEdition.SERVICE;
        case "ULTIMATE":
            return EProductEdition.ULTIMATE;
        case "NotValid":
            return undefined;
        default:
            throw new Error(`Unknown product edition '${edition}' received from API.`);
    }
}
