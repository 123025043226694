import React from "react";
import ErrorMessageScreen from "../ErrorMessageScreen/ErrorMessageScreen";
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { hasError, error, errorInfo } = this.state;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { children, showDetails, errorMessage, className, style } = this.props;
        if (hasError)
            return (React.createElement(ErrorMessageScreen, { error: error, errorInfo: errorInfo, showDetails: showDetails, errorMessage: errorMessage, className: className, style: style }));
        return children;
    }
}
