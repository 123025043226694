import { sendPostRequest } from "../../helpers";
import { isIUserObjectResponse } from "../../interfaces/IUserObjectResponse";
export default function login(data) {
    return sendPostRequest("/security/user/login", data, {
        withCredentials: true,
    })
        .then((result) => {
        if (result.status === 200 && isIUserObjectResponse(result.data))
            return result.data;
        throw new Error(result.data);
    })
        .catch((error) => {
        var _a;
        throw new Error(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || "");
    });
}
