/* eslint-disable no-console */
/**
 * Console.log works great in web or in desktop with a remote debugger.
 * But if used in production the console.log is forwarded to jxbrowser's
 * logging infrastructure (compare addConsoleListener).
 * This listener does not support additional none-string arguments
 * as used in our sources. Therefore we convert all arguments to one string
 * if in production.
 */
const origLog = console.log;
const origInfo = console.info;
function wrappedLog(logFn) {
    /* eslint-disable-next-line prefer-rest-params */
    const args = Array.from(arguments);
    const converted = args.map((o) => (typeof o === "string" ? o : JSON.stringify(o)));
    logFn.apply(console, [converted.join(";")]);
}
export default function overrideConsoleLog() {
    console.log = wrappedLog.bind(this, origLog);
    console.info = wrappedLog.bind(this, origInfo);
}
