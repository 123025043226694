import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GRIDVIS_NAME } from "@janitza/websdk-lib-oem";
import { useIntl } from "react-intl";
import ProjectContext from "./ProjectContext";
import { INVALIDATION_INTERVAL_MS, LOCALSTORAGE_KEY } from "./config";
import { getContextDataFromLsOrApi, useUpdateContextStateContiniously } from "../helpers";
import { getContextData as getContextDataFromApi } from "./helpers";
import { NotificationsContext } from "../NotificationsContext";
import i18n from "./ProjectProvider.i18n";
export default function ProjectProvider({ children }) {
    const { formatMessage } = useIntl();
    const [projects, setProjects] = useState([]);
    const { push: pushNotification } = useContext(NotificationsContext);
    const mainProject = useMemo(() => projects.find((it) => it.isMainProject), [projects]);
    const projectCount = useMemo(() => projects.length, [projects]);
    const isMainProjectSet = useMemo(() => !!mainProject, [mainProject]);
    const updateState = useCallback(() => {
        getContextDataFromLsOrApi(LOCALSTORAGE_KEY, getContextDataFromApi)
            .then((data) => {
            if (data) {
                setProjects(data.projects);
            }
            else {
                setProjects([]);
            }
        })
            .catch(console.error);
    }, []);
    useUpdateContextStateContiniously(updateState, LOCALSTORAGE_KEY, INVALIDATION_INTERVAL_MS);
    useEffect(() => {
        if (projects.some((it) => it.isRequiringDatabaseMigration)) {
            pushNotification({
                type: "ERROR",
                isClosable: true,
                timeoutMs: 10 * 1000,
                title: formatMessage(i18n.requiredMigrationTitle),
                content: formatMessage(i18n.requiredMigration, { gridVisName: GRIDVIS_NAME }),
            });
        }
    }, [formatMessage, projects, pushNotification]);
    const context = useMemo(() => {
        return {
            projects,
            mainProject,
            isMainProjectSet,
            projectCount,
        };
    }, [projects, mainProject, isMainProjectSet, projectCount]);
    return React.createElement(ProjectContext.Provider, { value: context }, children);
}
