import React from "react";
import styles from "./TextBox.module.scss";

interface IProps {
  caption: React.ReactNode;
  children: React.ReactNode;
}

export default function ImgTextBox({ caption, children }: IProps): React.ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.caption}>{caption}</div>
      <p>{children}</p>
    </div>
  );
}
