export var EQFClassName;
(function (EQFClassName) {
    EQFClassName["BUTTON"] = "qf-Button";
    EQFClassName["INPUT"] = "qf-Input";
    EQFClassName["LABEL"] = "qf-Label";
    EQFClassName["LABEL_PANELTITLE"] = "qf-Label-PanelTitle";
    EQFClassName["LABEL_WINDOWTITLE"] = "qf-Label-WindowTitle";
    EQFClassName["TEXT"] = "qf-Text";
    EQFClassName["PANEL"] = "qf-Panel";
    EQFClassName["CHECKBOX"] = "qf-CheckBox";
    EQFClassName["TEXTFIELD"] = "qf-TextField";
    EQFClassName["SLIDER"] = "qf-Slider";
    EQFClassName["RADIOBUTTON"] = "qf-RadioButton";
    EQFClassName["SPINNER"] = "qf-Spinner";
    EQFClassName["SELECT"] = "qf-Select";
    EQFClassName["COMBOBOX"] = "qf-ComboBox";
    EQFClassName["LIST"] = "qf-List";
    EQFClassName["LIST_ITEM"] = "qf-ListItem";
    EQFClassName["LIST_ITEM_CHECKBOX"] = "qf-ListItemCheckBox";
    EQFClassName["TABLE"] = "qf-Table";
    EQFClassName["TABLE_HEADER_ROW"] = "qf-TableHeader";
    EQFClassName["TABLE_HEADER_CELL"] = "qf-TableHeaderCell";
    EQFClassName["TABLE_BODY_ROW"] = "qf-TableRow=";
    EQFClassName["TABLE_BODY_CELL"] = "qf-TableCell";
    EQFClassName["TREE"] = "qf-Tree";
    EQFClassName["TREE_NODE"] = "qf-TreeNode";
    EQFClassName["TREE_EXPANDER"] = "qf-TreeExpander";
    EQFClassName["TREE_CHECKBOX"] = "qf-TreeCheckbox";
    EQFClassName["DIALOG"] = "qf-Dialog";
    EQFClassName["MENU"] = "qf-Menu";
    EQFClassName["MENU_ITEM"] = "qf-MenuItem";
    EQFClassName["TABPANEL"] = "qf-TabPanel";
    EQFClassName["TABPANEL_ITEM"] = "qf-TabPanelItem";
    EQFClassName["GRAPHICS"] = "qf-Graphics";
    EQFClassName["COLORPICKER"] = "qf-ColorPicker";
})(EQFClassName || (EQFClassName = {}));
