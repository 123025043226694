/**
 * Returns the content of the current local storage as a list of key-value-pair.
 *
 * Warning: This method does not (yet) filter for gve-only entries
 * and does not parse the raw values into they respective types.
 * Therefor, the returned value from this method may include specila characters, e.g. quotes.
 *
 * @todo Filter for entries that have the "gve" prefix
 * @todo Parse values into their respective types (strip quotes)
 * @returns List of local storage entries
 */
export default function getAll() {
    var _a;
    var entries = [];
    var keys = Object.keys(localStorage);
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var value = localStorage.getItem(keys[i]);
        entries.push((_a = {}, _a[key] = value, _a));
    }
    return entries;
}
