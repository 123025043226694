import React from "react";
import styles from "../Content.module.scss";
import TitleElement from "./TitleElement/TitleElement";
import ImgTextBox from "./ImgTextBox/ImgTextBox";
import TextBox from "./TextBox/TextBox";

// images
import Hero from "../../../img_9_EN/mainscreen_EN.png";

// images language specific
import ConsumptionAnaysis from "../../../img_9_EN/Verbrauchsanalyse_EN.png";
import HierarchyUser from "../../../img_9_EN/HierarchyUser_EN.png";
import CustomTemplate from "../../../img_9_EN/EigeneVorlagen_EN_1.png";
import GridVisTemplate from "../../../img_9_EN/GridVisTemplate_EN_2.png";
import Dashboard from "../../../img_9_EN/Dashboard_EN.png";
import DeviceDashboard from "../../../img_9_EN/GeraetDashboard_EN.png";
import HarmonicsObject from "../../../img_9_EN/Harmonics_EN.png";
import BarchartObject from "../../../img_9_EN/Balkendiagramm_EN.png";

export default function ContentEN(): React.ReactElement {
  return (
    <div className={styles.content}>
      <div className={styles.heroWrapper}>
        <header>
          <TitleElement />
          <div className={styles.subtitle}>
            The new Overview: Optimized <strong>List Function</strong> and
            <br /> a <strong>Hierarchy</strong> for all Users
          </div>
        </header>
        <img className={styles.heroImg} src={Hero} alt="List Function Screenshot" />
        <div className={styles.backdrop} />
      </div>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            New <strong>List Function</strong> and expanded <strong>Hierarchy Management</strong>
            <br />
            These are the <strong>Highlights</strong>
          </div>
          <div className={styles.subcaption}> The new Overview brings improvements in Usability and Performance </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={HierarchyUser}
              caption="Optimized List Function and expanded Hierarchy Management "
              altText="List Function"
            >
              Our revised list function comes with numerous optimizations, focusing on usability. The enhanced hierarchy
              management stands out, allowing created hierarchies to be accessible to all GridVis users. It also
              provides a private area for individual customization with a unique folder structure.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={ConsumptionAnaysis}
              caption="Meaningful Consumption Analysis: Display sums in the List"
              altText="Consumption Analysis"
            >
              We have revamped the consumption analysis in the list function. Effortlessly gain a detailed overview of
              the sums of energy consumption in your list. To access the consumption analysis, simply add the desired
              energy consumption as a separate column and activate the sum display. You have the flexibility to insert
              as many time periods into a list as needed. Using the selection column, you can perform a
              &quot;what-if&quot; analysis. A powerful and easy access to crucial energy data! This feature is available
              from the Expert Edition onwards.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={DeviceDashboard}
              caption="Open Dashboards directly from a List in the Overview "
              altText="Device Dashboards"
            >
              With the new Dashboard Editor starting from GridVis 9.0, we&apos;ve made dashboard creation easier. In the
              latest version 9.1, we offer additional convenience: Open dashboards directly from a list in the overview.
              Simply assign one or more rows to dashboards and view them immediately in the overview, without switching
              to the dashboard management. You can quickly switch between dashboards and adjust their time
              representation as needed. A practical and user-friendly update for your quick and easy data analysis!
            </ImgTextBox>
            <div className={styles.imgFlexWrapper}>
              <img src={Dashboard} className={styles.flexImage} alt="Overview Dashboard" />
            </div>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.darkWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Dashboard <strong>Templates</strong>
          </div>
          <div className={styles.subcaption}>
            New <strong>pre-made templates</strong> simplify the creation of dashboards! Create your own templates to
            make the dashboards even more <strong>personalized.</strong>
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={GridVisTemplate}
              caption="New Templates simplify Dashboard creation"
              altText="GridVis Templates"
              theme="light"
            >
              With our latest release, we offer you a selection of pre-made templates to make the dashboard creation
              process even easier. Use them as a starting point, customize them to your liking, or use them directly in
              your projects. An effective way to save time and be productive.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={CustomTemplate}
              caption="Create your own Dashboard Templates"
              altText="Custom Templates"
              theme="light"
            >
              Apart from the templates we provide, you now have the option to save individual dashboards as templates.
              This can be done in the dashboard settings. This way, you always have an overview and can start your
              projects even faster.
            </ImgTextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Expansions for <strong>Dashboards</strong> and <strong>Reports</strong>
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.textImgRow}>
              <TextBox caption="Precise Harmonic Analysis: The new PQ Object">
                The new PQ object &quot;Harmonics (Column Chart)&quot; expands the portfolio of visualization objects
                for dashboards and reports in GridVis. With this tool, historical harmonic distortions can be
                efficiently visualized. The object presents these as a column chart, providing a clear and illustrative
                overview of important PQ data. This object is a meaningful addition to the existing PQ objects,
                contributing to better visualizing the measured power quality parameters.
              </TextBox>
              <img className={styles.flexImage} src={HarmonicsObject} alt="Harmonics Object" />
            </div>
            <div className={styles.textImgRow}>
              <img className={styles.flexImage} src={BarchartObject} alt="Object Bar Chart" />
              <TextBox caption="New Bar Chart for Quick Overview">
                Another addition in the field of data visualization is the new bar chart, which now displays
                measurements horizontally. In contrast to traditional bar charts, this horizontal representation allows
                for a faster comprehension of the data, as the displayed values are sorted in descending order. It is
                ideal for applications where quick insight and effective data analysis are crucial. With this tool,
                users can analyze their data more efficiently and make informed decisions more quickly.
              </TextBox>
            </div>
            <TextBox caption="Write Modbus address from the Dashboard">
              With our new &quot;Write Modbus Value&quot; object, you can write parameters and states directly from your
              dashboard to a specified Modbus address. The special feature is that you can not only parameterize
              measuring devices directly but also dynamically adjust residual current limit values or even switch
              digital outputs. All of this directly from your dashboard, providing a valuable addition to plant control
              and monitoring. This can be done either through a manual input field or with predefined buttons. Clicking
              on such a button automatically transfers the set value. This opens up a variety of applications that were
              not previously achievable.
            </TextBox>
            <TextBox caption="Multiple Linear Regression">
              Thanks to the new &quot;Multiple Linear Regression&quot; object, you can now conduct even more in-depth
              analyses. This tool allows, for example, the examination of energy consumption against various parameters
              to determine if they influence energy consumption. The identified dependencies are clearly presented in a
              table, ensuring you always have a comprehensive overview.
            </TextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapperMore}>
        <div className={styles.backdropReverse} />
        <div className={styles.sectionContent}>
          <div className={styles.caption} style={{ color: "#fff" }}>
            <strong>More functions at a glance</strong>
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className={styles.heading}>Expanded Voltage Quality Report according to IEEE 519</div>
              <p>
                The voltage quality report in GridVis has been updated according to the current IEEE 519 - 2022
                standard. This data export, configurable in the GridVis desktop application, allows for evaluation over
                the web as usual. A feature of this report is the ability to generate current and voltage analyses
                independently, as needed. By adapting to the IEEE 519 - 2022 standard, the data export ensures a
                consistent and standardized assessment of voltage quality, supporting optimal monitoring and control of
                your facilities.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Optimized Navigation in the Line Chart </div>
              <p>
                Our line charts on dashboards now offer improved navigation with the introduction of the
                &quot;Navigator.&quot; This function, which can be activated in the settings, allows you to intuitively
                navigate through time periods in the presentation mode of your dashboard. Zoom in and out of any
                sections to delve deeper into your measurement data and highlight important details. This new function
                makes it easier to recognize patterns and deviations in your data, supporting efficient analysis.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Expanded Link Function for efficient Dashboard use </div>
              <p>
                The link function in our dashboards has been expanded. Each link can be assigned to a specific device,
                whose data will be displayed when opening the dashboard. This functionality opens up the possibility to
                switch directly from an overview of various devices to a detailed view for a selected measuring device
                with little effort. This way, you can create a dashboard that consistently displays typical measurement
                data for a device with minimal effort.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.verticalSpace} />
      </section>
    </div>
  );
}
