import { EPriority, set as setInLs } from "@janitza/websdk-lib-localstorage";
import { login as apiLogin } from "../../../../helpers/apis";
import extractUserFromIUserObjectResponse from "./extractUserFromIUserObjectResponse";
import { LOCALSTORAGE_KEY_DEPRECATED } from "../../config";
export default function login(username, password) {
    return apiLogin({ username, password }).then((data) => {
        // To be instantly logged in within the old container, we write the response to the localstorage
        setInLs(LOCALSTORAGE_KEY_DEPRECATED, data, { priority: EPriority.HIGH });
        return {
            isDefaultPasswordEnabled: data.defaultPassword || false,
            user: data.loggedIn ? extractUserFromIUserObjectResponse(data) : undefined,
            isLoggedIn: data.loggedIn || false,
        };
    });
}
