import { useMemo, useState } from "react";
import { useEventListener } from "..";
function normalizeToArray(value) {
    if (value instanceof Array) {
        return value;
    }
    return value != null ? [value] : [];
}
function isEventTargetInside(event, nodes) {
    const { target } = event;
    return target instanceof Node && nodes.some((el) => el === null || el === void 0 ? void 0 : el.contains(target));
}
/**
 * This hook listens to click events on document level. The callback function is called if and only if
 * a) the target of the last mousedown/touchstart event is not contained inside the container nodes AND
 * b) the target of the document click is not contained inside the container nodes, too.
 * The container nodes can be set with the returned setNodes callback function.
 * To disable the hook, pass null or undefined as the onClickOutside argument.
 */
export function useClickOutsideListener(onClickOutside) {
    const [nodes, setNodes] = useState(undefined);
    const eventHandler = useMemo(() => {
        const nodeList = normalizeToArray(nodes);
        if (!onClickOutside || nodeList.length === 0) {
            return undefined;
        }
        return (event) => {
            if (!isEventTargetInside(event, nodeList)) {
                onClickOutside(event);
            }
        };
    }, [nodes, onClickOutside]);
    useEventListener(document, "pointerdown", eventHandler);
    return { nodes, setNodes };
}
