"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STORAGE_KEY_DESIRED_WEEKSTART_WITH_PREFIX = exports.STORAGE_KEY_DESIRED_WEEKSTART = exports.STORAGE_KEY_DESIRED_COUNTRY_WITH_PREFIX = exports.STORAGE_KEY_DESIRED_COUNTRY = exports.STORAGE_KEY_DESIRED_LOCALE_WITH_PREFIX = exports.STORAGE_KEY_DESIRED_LOCALE = void 0;
// eslint-disable-next-line import/prefer-default-export
exports.STORAGE_KEY_DESIRED_LOCALE = "locale";
exports.STORAGE_KEY_DESIRED_LOCALE_WITH_PREFIX = "gve-8locale"; // Used to watch/trigger events on localstorage
exports.STORAGE_KEY_DESIRED_COUNTRY = "country";
exports.STORAGE_KEY_DESIRED_COUNTRY_WITH_PREFIX = "gve-8country";
exports.STORAGE_KEY_DESIRED_WEEKSTART = "weekStart";
exports.STORAGE_KEY_DESIRED_WEEKSTART_WITH_PREFIX = "gve-8weekStart";
// CAUTION: If you plan to change the key, make sure to change it within the following file as well:
// /gv/netbeans/web-embed/impl-suite/webembed/src/main/java/de/janitza/pasw/webembed/impl/browser/NavigationManagment.java#64
