import { useCallback, useEffect } from "react";
import writeToLocalstorage from "./writeToLocalstorage";
/**
 * React hook to initially and continiously update context's state according to updateState method and other parameters.
 * @param localStorageKey
 * @param invalidationIntervalMs
 * @param updateState
 */
export default function useUpdateContextStateContiniously(updateState, localStorageKey, invalidationIntervalMs = 1000) {
    const invalidateStateContiniously = useCallback(() => {
        return setInterval(() => {
            writeToLocalstorage(localStorageKey, undefined);
            updateState();
        }, invalidationIntervalMs);
    }, [invalidationIntervalMs, localStorageKey, updateState]);
    useEffect(() => {
        updateState();
        const interval = invalidateStateContiniously();
        return () => clearInterval(interval);
    }, [invalidateStateContiniously, updateState]);
}
