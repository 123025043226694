import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Spinner } from "@janitza/ui";
import { WHITE } from "@janitza/websdk-lib-colors";
import styles from "./UserPanel.module.scss";
import i18n from "./UserPanel.i18n";
import { LoginModalWindow } from "../../../../modalWindows";
import { IfUserIsLoggedIn, IfUserIsNotLoggedIn, ModalsContext, NotificationsContext, UserContext, } from "../../../../../contexts";
import { EUserType } from "../../../../../enums";
export default function UserPanel({ className, style }) {
    const { formatMessage } = useIntl();
    const { push: pushModal, pop: popModal } = useContext(ModalsContext);
    const { push: pushNotification } = useContext(NotificationsContext);
    const { user, logout } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const userDisplayName = (user === null || user === void 0 ? void 0 : user.type) === EUserType.ANONYMOUS ? formatMessage(i18n.anonymousUser) : user === null || user === void 0 ? void 0 : user.username;
    const onLogout = useCallback(() => {
        if (isLoading)
            return;
        setIsLoading(true);
        logout()
            .then(() => {
            pushNotification({ type: "OK", title: formatMessage(i18n.logoutSuccessfull), content: "" });
        })
            .catch((error) => {
            console.error(error);
            pushNotification({
                type: "ERROR",
                title: formatMessage(i18n.logoutFailed),
                content: error.message,
            });
        })
            .finally(() => setIsLoading(false));
    }, [isLoading, formatMessage, logout, pushNotification]);
    return (React.createElement("div", { className: classNames(styles.userPanel, className), style: style },
        React.createElement(IfUserIsNotLoggedIn, null,
            React.createElement("div", { className: classNames("qf-Button", styles.loginButton), onClick: () => {
                    pushModal({
                        content: React.createElement(LoginModalWindow, { onSuccess: popModal, onClose: popModal }),
                        doesCoverContainer: true,
                    });
                } },
                React.createElement("div", { className: styles.logo }),
                React.createElement("div", { className: styles.text }, formatMessage(i18n.login)))),
        React.createElement(IfUserIsLoggedIn, null,
            isLoading && React.createElement(Spinner, { color: WHITE, size: 14 }),
            !isLoading && (React.createElement("div", { className: classNames("qf-Button", styles.logoutButton), onClick: onLogout }, formatMessage(i18n.logout))),
            React.createElement("div", { className: styles.credentials },
                React.createElement("div", { className: classNames(styles.userTypeIcon, user && styles[user.type]) }),
                React.createElement("span", { className: classNames("qf-Label", styles.username) }, userDisplayName)))));
}
