import React from "react";
import classNames from "classnames";
import styles from "./Spinner.module.scss";
import { EQFClassName } from "../../../enums";
export default function Spinner({ className = "", color, dataCy = "spinner", size, style, }) {
    let divStyle = {};
    let spinnerStyle = {};
    if (size) {
        const sizeStyle = { width: `${size}px`, height: `${size}px`, borderWidth: `${size / 10}px` };
        spinnerStyle = Object.assign(Object.assign({}, spinnerStyle), sizeStyle);
        divStyle = Object.assign(Object.assign({}, spinnerStyle), sizeStyle);
    }
    if (color)
        divStyle = Object.assign(Object.assign({}, divStyle), { borderColor: `${color} transparent transparent transparent` });
    return (React.createElement("div", { className: classNames(styles.spinner, className, EQFClassName.SPINNER), style: Object.assign(Object.assign({}, spinnerStyle), style), "data-cy": dataCy },
        React.createElement("div", { className: styles.fragments },
            React.createElement("div", { style: divStyle }),
            React.createElement("div", { style: divStyle }),
            React.createElement("div", { style: divStyle }),
            React.createElement("div", { style: divStyle }))));
}
