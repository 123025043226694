import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import React from "react";
import { getMessagesForLocale, NestedIntlProvider } from "@janitza/websdk-lib-translations";
import translations from "../translations/translations.json";
export default function IntlProvider({ children, locale }) {
    if (locale)
        moment().locale(locale);
    return (React.createElement(NestedIntlProvider, { messages: getMessagesForLocale(translations, locale), locale: locale }, children));
}
