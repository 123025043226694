import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { getSupportedLocales, setDesiredLocale } from "@janitza/websdk-lib-translations";
import classNames from "classnames";
import styles from "./LanguageSelector.module.scss";
import i18n from "./LanguageSelector.i18n";
function getMessageDescriptorForLanguageTitleByLocale(locale) {
    switch (locale) {
        case "en":
            return i18n.languageTitleEn;
        case "de":
            return i18n.languageTitleDe;
        case "es":
            return i18n.languageTitleEs;
        case "it":
            return i18n.languageTitleIt;
        default:
            throw new Error(`No message descriptor found for language title of locale '${locale}'.`);
    }
}
export default function LanguageSelector({ className, style }) {
    const { formatMessage, locale } = useIntl();
    const [currentLocale, setCurrentLocale] = useState(locale);
    const supportedLocales = getSupportedLocales();
    const handleChangeLanguage = useCallback((event) => {
        const selected = event.target.value;
        setDesiredLocale(selected);
        setCurrentLocale(selected);
    }, []);
    return (React.createElement("div", { className: classNames(styles.languageSelector, className), style: style },
        React.createElement("div", { className: styles.icon }),
        React.createElement("select", { className: "qf-Select", defaultValue: currentLocale, onChange: handleChangeLanguage }, supportedLocales.map((entry) => {
            const title = formatMessage(getMessageDescriptorForLanguageTitleByLocale(entry));
            return (React.createElement("option", { key: entry, title: title, value: entry }, title));
        }))));
}
