import { defineMessages } from "react-intl";
export default defineMessages({
    requiredMigrationTitle: {
        id: "container.notificationarea.requiredMigrationTitle",
        defaultMessage: "Database migration required",
        description: "Title being shown if a project need a database migration.",
    },
    requiredMigration: {
        id: "container.notificationarea.requiredMigration",
        defaultMessage: "At least one project requires a database migration!" +
            " The migration can currently only be started from the {gridVisName} Desktop." +
            " Open your {gridVisName} Desktop and start the migration manually with a right click on the project." +
            " After a restart of the service you can use the {gridVisName} WEB application again as usual." +
            " Please note that after a database migration a downgrade is no longer possible!",
        description: "Text being shown if a project need a database migration.",
    },
});
