import { useIntl } from "react-intl";
import { useContext, useMemo } from "react";
import i18n from "./useNavBarEntries.i18n";
import { EProductEdition, EProductEditionPremo } from "../../../../../enums";
import { FeatureTogglesContext, ProductContext, ProjectContext, UserContext } from "../../../../../contexts";
export default function useNavBarEntries() {
    const { formatMessage, locale } = useIntl();
    const energyPrefix = `/energy/${locale}`;
    const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
    const { isEditionAtLeast, isEditionPremoAtLeast, isPremoEnabled } = useContext(ProductContext);
    const { hasPermission } = useContext(UserContext);
    // Licensing
    const isAtLeastService = isEditionAtLeast(EProductEdition.SERVICE);
    const isAtLeastUltimate = isEditionAtLeast(EProductEdition.ULTIMATE);
    const isAtLeastStandard = isEditionPremoAtLeast(EProductEditionPremo.STANDARD);
    const isAtLeastStandardOrService = isAtLeastStandard || isAtLeastService;
    const { isMainProjectSet } = useContext(ProjectContext);
    const entries = useMemo(
    // eslint-disable-next-line complexity
    () => [
        {
            id: "overview",
            name: formatMessage(i18n.overview),
            href: "/overview",
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                isFeatureToggleEnabled("pasw-feature-spa-devicelist2") &&
                isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "devicelist",
            name: formatMessage(i18n.overview),
            href: "/devicelist",
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                isFeatureToggleEnabled("pasw-feature-spa-devicelist1") &&
                isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "overview-old",
            name: formatMessage(i18n.overview),
            href: `${energyPrefix}/overview.html`,
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                !isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "dashboards",
            name: formatMessage(i18n.dashboards),
            href: isPremoEnabled ? "/dashboards" : `${energyPrefix}/dashboards.html`,
            isShown: (isAtLeastStandard || isAtLeastUltimate) && isMainProjectSet,
        },
        {
            id: "reports",
            name: formatMessage(i18n.reports),
            href: "/reports",
            isShown: isMainProjectSet && isAtLeastStandard && hasPermission("MANDANT_ENERGY:Reports:READ"),
        },
        {
            id: "alarms",
            name: formatMessage(i18n.alarms),
            href: `${energyPrefix}/alarm-list.html`,
            isShown: isMainProjectSet && isAtLeastStandardOrService,
        },
    ], [
        energyPrefix,
        formatMessage,
        hasPermission,
        isAtLeastStandard,
        isAtLeastStandardOrService,
        isAtLeastUltimate,
        isFeatureToggleEnabled,
        isMainProjectSet,
        isPremoEnabled,
    ]);
    return [entries];
}
