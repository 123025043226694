import "normalize.css";
import "source-sans-pro/source-sans-pro.css";
import React from "react";
import classNames from "classnames";
import { isDesktop } from "@janitza/websdk-lib-desktop";
import { Content, Footer, Header, ModalArea, NotificationArea } from "../layout";
import styles from "./Container.module.scss";
import { ContextProvider } from "../contextProviders";
export default function Container({ children, isHeaderHidden = isDesktop(), isFooterHidden = isDesktop(), onLoginSuccessfull, onLogoutSuccessfull, className, style, }) {
    return (React.createElement(ContextProvider, { onLoginSuccessfull: onLoginSuccessfull, onLogoutSuccessfull: onLogoutSuccessfull },
        React.createElement("div", { className: classNames(styles.container, className), style: style },
            !isHeaderHidden && React.createElement(Header, { className: styles.header }),
            React.createElement(ModalArea, null),
            React.createElement(NotificationArea, null),
            React.createElement(Content, { isHeaderHidden: isHeaderHidden, isFooterHidden: isFooterHidden, className: styles.content }, children),
            !isFooterHidden && React.createElement(Footer, { className: styles.footer }))));
}
