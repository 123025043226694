import React, { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import validator from "validator";
import { Spinner } from "@janitza/ui";
import { BLACK_100 } from "@janitza/websdk-lib-colors";
import styles from "./ForgotPasswordModalWindow.module.scss";
import LegacyModalWindow from "../LegacyModalWindow/LegacyModalWindow";
import i18n from "./ForgotPasswordModalWindow.i18n";
import LegacyModalWindowTable from "../LegacyModalWindowTable/LegacyModalWindowTable";
import LegacyModalWindowButton from "../LegacyModalWindowButton/LegacyModalWindowButton";
import { NotificationsContext, UserContext } from "../../../contexts";
export default function ForgotPasswordModalWindow({ onSuccess, onClose, className, style, }) {
    const { formatMessage } = useIntl();
    const { push: pushNotification } = useContext(NotificationsContext);
    const { sendPasswordResetMail } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const onSubmit = useCallback(() => {
        if (isLoading)
            return;
        if (validator.isEmpty(email))
            return;
        setIsLoading(true);
        sendPasswordResetMail(email)
            .then((result) => {
            pushNotification({
                type: "OK",
                title: formatMessage(i18n.forgotPwSuccessfulTitle),
                content: result,
            });
            if (onSuccess)
                onSuccess();
        })
            .catch((error) => {
            console.error(error);
            pushNotification({
                type: "ERROR",
                title: formatMessage(i18n.forgotPwFailedTitle),
                content: error.message,
            });
        })
            .finally(() => setIsLoading(false));
    }, [isLoading, email, sendPasswordResetMail, pushNotification, formatMessage, onSuccess]);
    const footerRight = (React.createElement(React.Fragment, null,
        isLoading && React.createElement(Spinner, { color: BLACK_100, style: { margin: "0 20px" } }),
        !isLoading && (React.createElement(LegacyModalWindowButton, { icon: "EMAIL", onClick: onSubmit }, formatMessage(i18n.sendEmail))),
        React.createElement(LegacyModalWindowButton, { icon: "CROSS", onClick: onClose }, formatMessage(i18n.abort))));
    return (React.createElement(LegacyModalWindow, { footerRight: footerRight, onClose: onClose, className: classNames(styles.window, className), style: style },
        React.createElement("form", null,
            React.createElement(LegacyModalWindowTable, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 2 },
                            React.createElement("div", { className: styles.headerLine }),
                            formatMessage(i18n.forgotYourPassword)))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            formatMessage(i18n.emailAddress),
                            ":"),
                        React.createElement("td", null,
                            React.createElement("input", { type: "email", name: "email", autoComplete: "off", className: classNames(styles.input, validator.isEmpty(email) && styles.isInvalid, "qf-TextField"), value: email, placeholder: formatMessage(i18n.placeholderEmail), onChange: (event) => setEmail(event.target.value), onKeyPress: (event) => {
                                    if (event.key === "Enter")
                                        onSubmit();
                                } }))))))));
}
