import { useCallback, useEffect, useMemo, useState } from "react";
import get from "./get";
import Storage from "../Storage/Storage";
/**
 * A react hook to listen for changes on a specific storage entry.
 * @param key The key of the localstorage entry to listen on.
 * @returns The new date read from localstorage.
 */
export default function useLocalStorageEntry(key) {
    var storage = useMemo(function () { return new Storage(); }, []);
    var _a = useState(get(storage, key)), data = _a[0], setData = _a[1];
    var eventListener = useCallback(function (event) {
        if (event.key === key)
            setData(get(storage, key));
    }, [key]);
    useEffect(function () {
        window.addEventListener("storage", eventListener);
        return function () { return window.removeEventListener("storage", eventListener); };
    }, [eventListener]);
    return data;
}
