import React, { useCallback } from "react";
import classNames from "classnames";
import styles from "./Modal.module.scss";
export default function Modal({ children, isSimplyClosable = true, doesCoverContainer = false, onClose, className, style, }) {
    const handleOnClickModal = useCallback((event) => {
        if (isSimplyClosable && onClose)
            onClose();
        event.stopPropagation();
    }, [isSimplyClosable, onClose]);
    const handleOnClickContent = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const keyUpHandler = useCallback((event) => {
        if (isSimplyClosable && event.key === "Escape" && onClose)
            onClose();
    }, [isSimplyClosable, onClose]);
    document.addEventListener("keyup", keyUpHandler, false);
    return (React.createElement("div", { onClick: handleOnClickModal, className: classNames(styles.modal, doesCoverContainer && styles.doesCoverContainer, isSimplyClosable && styles.isSimplyClosable, className), style: style },
        React.createElement("div", { className: styles.content, onClick: handleOnClickContent }, children)));
}
