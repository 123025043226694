import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import styles from "./LegacyModalWindow.module.scss";
export default function LegacyModalWindow({ children, header, footerLeft, footerRight, onClose, className, contentClassName, style, dataCy = "LegacyModalWindow", }) {
    const shouldShowHeader = !!header || !!onClose;
    return (React.createElement("div", { className: classNames(styles.window, className), style: style, "data-cy": dataCy },
        shouldShowHeader && (React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.title }, header),
            onClose && (React.createElement("div", { className: styles.iconBar },
                React.createElement("div", { className: styles.closeIcon, onClick: onClose },
                    React.createElement(FontAwesomeIcon, { icon: faTimes })))),
            React.createElement("div", { className: styles.clear }))),
        React.createElement("div", { className: classNames(styles.content, contentClassName) }, children),
        React.createElement("div", { className: styles.footer },
            React.createElement("div", { className: classNames(styles.section, styles.left) }, footerLeft),
            React.createElement("div", { className: classNames(styles.section, styles.right) }, footerRight))));
}
