import { EPriority } from "../../enums";
export default function getNumericValueForPriority(priority) {
    switch (priority) {
        case EPriority.HIGH:
            return 8;
        case EPriority.MEDIUM:
            return 5;
        case EPriority.LOW:
            return 1;
        default:
            throw new Error("Unknown priority ".concat(JSON.stringify(priority), " for localstorage entry"));
    }
}
