import React from "react";
import styles from "../Content.module.scss";
import TitleElement from "./TitleElement/TitleElement";
import ImgTextBox from "./ImgTextBox/ImgTextBox";
import TextBox from "./TextBox/TextBox";

// images
import Hero from "../../../img_9_EN/mainscreen_EN.png";

// images language specific
import ConsumptionAnaysis from "../../../img_9_EN/Verbrauchsanalyse_EN.png";
import HierarchyUser from "../../../img_9_EN/HierarchyUser_EN.png";
import CustomTemplate from "../../../img_9_EN/EigeneVorlagen_EN_1.png";
import GridVisTemplate from "../../../img_9_EN/GridVisTemplate_EN_2.png";
import Dashboard from "../../../img_9_EN/Dashboard_EN.png";
import DeviceDashboard from "../../../img_9_EN/GeraetDashboard_EN.png";
import HarmonicsObject from "../../../img_9_EN/Harmonics_EN.png";
import BarchartObject from "../../../img_9_EN/Balkendiagramm_EN.png";

export default function ContentES(): React.ReactElement {
  return (
    <div className={styles.content}>
      <div className={styles.heroWrapper}>
        <header>
          <TitleElement />
          <div className={styles.subtitle}>
            La nueva visión general: Función de lista optimizada y un árbol jerárquico para todos los usuarios
          </div>
        </header>
        <img className={styles.heroImg} src={Hero} alt="Editor de panel de control" />
        <div className={styles.backdrop} />
      </div>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Nueva función de lista y gestión jerárquica ampliada. Estos son los aspectos destacados
          </div>
          <div className={styles.subcaption}>
            La nueva función de listas aporta mejoras en la facilidad de uso y rendimiento
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={HierarchyUser}
              caption="Función de lista mejorada y gestión jerárquica ampliada"
              altText="Función de lista"
            >
              Nuestra función de lista mejorada ofrece numerosas optimizaciones, centrándose en la facilidad de uso. La
              gestión de jerarquías renovada destaca especialmente: permite que las jerarquías creadas sean accesibles
              para todos los usuarios de GridVis y también ofrece un área privada para personalización individual con
              una estructura de carpetas propia.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={ConsumptionAnaysis}
              caption="Análisis significativo de consumo: mostrar sumas en la lista"
              altText="Análisis consumo"
            >
              Hemos renovado el análisis de consumo en la función de lista. Obtenga fácilmente una visión detallada de
              las sumas de consumos de energía en su lista. Para acceder al análisis de consumo, simplemente agregue el
              consumo de energía deseado como una columna separada y active la visualización de sumas. El usuario tiene
              la flexibilidad de insertar tantos períodos de tiempo en una lista como sea necesario. Utilizando la
              columna de selección, puede realizar un análisis de &quot;qué pasaría, si&quot;. ¡Un acceso potente y
              fácil a datos energéticos importantes! Esta función está disponible a partir de la Expert Edition.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={DeviceDashboard}
              caption="Abrir paneles de control directamente desde una lista en la visión general"
              altText="Panel de control del dispositivo"
            >
              Con el nuevo Editor de Paneles de Control a partir de GridVis 9.0, hemos facilitado la creación de paneles
              de control. En la última versión 9.1, ofrecemos la comodidad adicional: abra paneles de control
              directamente desde una lista en la visión general. Para ello asigne simplemente una o más filas a paneles
              de control y véalos de inmediato en la visión general, sin cambiar a la gestión de paneles de control.
              Puede cambiar rápidamente entre paneles de control y ajustar su visualización de tiempo según sea
              necesario. ¡Una actualización práctica y fácil de usar para un análisis rápido y sencillo de datos!
            </ImgTextBox>
            <div className={styles.imgFlexWrapper}>
              <img src={Dashboard} className={styles.flexImage} alt="Screenshot Dashboard" />
            </div>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.darkWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}> Plantillas de los paneles de control </div>
          <div className={styles.subcaption}>
            ¡Las nuevas plantillas predefinidas simplifican la creación de paneles de control! Cree sus propias
            plantillas para utilizar los paneles de control de manera aún más personalizada.
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={GridVisTemplate}
              caption="Plantillas listas para usar para la creación de paneles de control"
              altText="Plantillas GridVis"
              theme="light"
            >
              Con nuestro último lanzamiento, le ofrecemos una selección de plantillas predefinidas para hacer aún más
              fácil el proceso de creación de paneles de control. Úselas como punto de partida, ajústelas según sus
              preferencias o úselas directamente en sus proyectos. Una manera efectiva de ahorrar tiempo y ser
              productivo.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={CustomTemplate}
              caption="Cree sus propias plantillas de paneles de control"
              altText="Plantillas propias"
              theme="light"
            >
              Además de las plantillas que proporcionamos, ahora tiene la opción de guardar paneles de control
              individuales como plantillas. Esto se puede hacer en la configuración del panel de control. De esta
              manera, siempre tiene una visión general y puede comenzar sus proyectos aún más rápido.
            </ImgTextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>Ampliación para paneles de control e informes </div>
          <div className={styles.flexWrapper}>
            <div className={styles.textImgRow}>
              <TextBox caption="Análisis armónico preciso: El nuevo objeto PQ ">
                El nuevo objeto PQ &quot;Armónicos (Diagrama de barras)&quot; amplía el portafolio de objetos de
                visualización para paneles de control e informes en GridVis. Con esta herramienta, las distorsiones
                armónicas históricas se pueden visualizar de manera eficiente. El objeto las representa como un diagrama
                de barras, proporcionando una visión clara e ilustrativa de los datos PQ importantes. Este objeto es una
                adición significativa a los objetos PQ existentes y contribuye a visualizar mejor los parámetros de
                calidad de potencia medidos.
              </TextBox>
              <img className={styles.flexImage} src={HarmonicsObject} alt="Armónicos del objeto" />
            </div>
            <div className={styles.textImgRow}>
              <img className={styles.flexImage} src={BarchartObject} alt="Gráfico de barras" />
              <TextBox caption="Nuevo gráfico de barras para una rápida visión general">
                Otra incorporación en el campo de la visualización de datos es el nuevo gráfico de barras, que muestra
                las mediciones de manera horizontal. A diferencia de los gráficos de barras tradicionales, esta
                representación horizontal permite una comprensión más rápida de los datos, ya que los valores mostrados
                están ordenados de manera descendente. Es ideal para aplicaciones donde la rápida comprensión y el
                análisis efectivo de datos son fundamentales. Con esta herramienta, los usuarios pueden analizar sus
                datos de manera más eficiente y tomar decisiones acertadas más rápidamente.
              </TextBox>
            </div>
            <TextBox caption="Escribir dirección Modbus desde el panel de control ">
              Con nuestro nuevo objeto &quot;Escritor Modubus&quot;, puede escribir parámetros y estados directamente
              desde su panel de control a una dirección Modbus especificada. La característica especial es que no solo
              puede parametrizar directamente dispositivos de medición, sino también ajustar dinámicamente los valores
              límite de corriente residual o incluso cambiar salidas digitales. Todo esto directamente desde su panel de
              control, proporcionando una valiosa adición al control y monitoreo de plantas. Esto se puede hacer ya sea
              a través de un campo de entrada manual o con botones predefinidos. Hacer clic en uno de estos botones
              transfiere automáticamente el valor establecido. Esto abre una variedad de aplicaciones que antes no eran
              realizables.
            </TextBox>
            <TextBox caption=" Regresión lineal múltiple ">
              Gracias al nuevo objeto &quot;Regresión Lineal Múltiple&quot;, ahora puede realizar análisis aún más
              profundos. Esta herramienta permite, por ejemplo, examinar el consumo de energía en relación con varios
              parámetros para determinar si estos tienen un impacto en el consumo de energía. Las dependencias
              identificadas se presentan de manera clara en una tabla, asegurando que siempre tenga una visión completa.
            </TextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapperMore}>
        <div className={styles.backdropReverse} />
        <div className={styles.sectionContent}>
          <div className={styles.caption} style={{ color: "#fff" }}>
            <strong>Otras funciones en un vistazo</strong>
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className={styles.heading}>Informe de calidad de voltaje ampliado según IEEE 519 </div>
              <p>
                El informe de calidad de voltaje en GridVis se ha actualizado según la norma IEEE 519 - 2022. Esta
                exportación de datos, configurable en la aplicación de escritorio de GridVis, también permite la
                evaluación a través de la web como de costumbre. Una característica de este informe es la capacidad de
                generar análisis de corriente y voltaje de manera independiente, según sea necesario. Al adaptarse a la
                norma IEEE 519 - 2022, la exportación de datos garantiza una evaluación consistente y estandarizada de
                la calidad del voltaje, lo que respalda el monitoreo y control óptimos de sus instalaciones.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Función de navegación optimizada en el gráfico de líneas </div>
              <p>
                Nuestros gráficos de líneas en los paneles de control ahora ofrecen una navegación mejorada con la
                introducción del &quot;Navegador&quot;. Esta función, que se puede activar en la configuración, le
                permite navegar de manera intuitiva a través de períodos de tiempo en el modo de presentación de su
                panel de control. Amplíe y reduzca cualquier sección para sumergirse más profundamente en sus datos de
                medición y resaltar detalles importantes. Esta nueva función facilita la identificación de patrones y
                desviaciones en sus datos y apoya un análisis eficiente.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>
                Función de enlace ampliada para un uso eficiente del panel de control
              </div>
              <p>
                La función de enlace en nuestros paneles de control se ha ampliado. A cada enlace se le puede asignar un
                dispositivo específico, cuyos datos se mostrarán al abrir el panel de control. Esta funcionalidad abre
                la posibilidad de cambiar directamente desde una visión general de varios dispositivos a una vista
                detallada para un dispositivo de medición seleccionado con poco esfuerzo. De esta manera, puede crear un
                panel de control que muestre constantemente datos de medición típicos de un dispositivo con un esfuerzo
                mínimo.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.verticalSpace} />
      </section>
    </div>
  );
}
