import { get, set, EPriority } from "@janitza/websdk-lib-localstorage";
import { LS_KEY_RELEASENOTES_VERSION } from "../../config";
export function getData() {
    return new Promise((resolve) => {
        resolve(get(LS_KEY_RELEASENOTES_VERSION));
    });
}
export function setData(data) {
    return new Promise((resolve) => {
        resolve(set(LS_KEY_RELEASENOTES_VERSION, data, { priority: EPriority.HIGH }));
    });
}
