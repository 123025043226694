import Storage from "./Storage/Storage";
import { get as getInternal, set as setInternal } from "./functions";
import getAll from "./functions/getAll";
import useLocalStorageEntry from "./functions/useLocalStorageEntry";
var STORAGE = new Storage();
export * from "./enums";
export * from "./interfaces";
export function get(key) {
    return getInternal(STORAGE, key);
}
export function set(key, value, options) {
    return setInternal(STORAGE, key, value, options);
}
export { getAll, useLocalStorageEntry };
