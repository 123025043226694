import React from "react";
import { AlarmsProvider, FeatureTogglesProvider, ModalsProvider, NotificationsProvider, ProductProvider, ProjectProvider, UserProvider, } from "../../../contexts";
export default function ContextProviderForEnergy({ children, onLoginSuccessfull, onLogoutSuccessfull, }) {
    return (React.createElement(NotificationsProvider, null,
        React.createElement(ModalsProvider, null,
            React.createElement(FeatureTogglesProvider, null,
                React.createElement(ProductProvider, null,
                    React.createElement(ProjectProvider, null,
                        React.createElement(UserProvider, { onLoginSuccessfull: onLoginSuccessfull, onLogoutSuccessfull: onLogoutSuccessfull },
                            React.createElement(AlarmsProvider, null, children))))))));
}
