import { user } from "../../../../helpers/apis";
export default function getContextData() {
    return user().then((data) => {
        if (!data)
            return undefined;
        const { featureToggleClassesExt } = data;
        return {
            featureToggles: featureToggleClassesExt || {},
        };
    });
}
