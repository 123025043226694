import classNames from "classnames";
import React from "react";
import styles from "../../Content.module.scss";
import GridVisLogo from "../../../../img/GridVisR.svg";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function TitleElement({ className, style }: IProps): React.ReactElement {
  return (
    <div className={classNames(styles.title, className)} style={style}>
      <div className={styles.logoWrapper}>
        <img alt="GridVis Logo" src={GridVisLogo} className={styles.logo} />
      </div>
      <div className={styles.version}>9.1</div>
    </div>
  );
}
