var EActiveRealm;
(function (EActiveRealm) {
    EActiveRealm["ACTIVE_DIRECTORY"] = "ACTIVE_DIRECTORY";
    EActiveRealm["DEFAULT"] = "DEFAULT";
})(EActiveRealm || (EActiveRealm = {}));
export function isIUserObjectResponse(object) {
    return (object.userId !== undefined &&
        object.userType !== undefined &&
        object.username !== undefined);
}
