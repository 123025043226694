import { EProductEditionPremo } from "../../../../enums";
export default function getProductEditionPremoByApiString(edition) {
    switch (edition) {
        case "ESSENTIALS":
            return EProductEditionPremo.ESSENTIALS;
        case "STANDARD":
            return EProductEditionPremo.STANDARD;
        case "EXPERT":
            return EProductEditionPremo.EXPERT;
        case "HUB":
            return EProductEditionPremo.HUB;
        case "NOT_LICENSED":
            return EProductEditionPremo.NOT_LICENSED;
        case "":
            return undefined;
        default:
            throw new Error(`Unknown product edition '${edition}' received from API.`);
    }
}
