import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Spinner } from "@janitza/ui";
import { WHITE } from "@janitza/websdk-lib-colors";
import { NotificationsContext, ProductContext } from "../../../../../contexts";
import { trimPatchFromProductVersionIfReleaseVersion } from "./helpers";
import { PREVIEW_TOGGLE_CLICK_TIMES } from "../../../../../config";
import i18n from "./ProductNameAndVersion.i18n";
export default function ProductNameAndVersion({ className, style }) {
    const { formatMessage } = useIntl();
    const { name: productName, version, togglePreview, isInPreview } = useContext(ProductContext);
    const { push: pushNotification } = useContext(NotificationsContext);
    const [versionClickAmount, setVersionClickAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const handleOnClick = useCallback(() => {
        if (isLoading)
            return;
        let newValue = versionClickAmount + 1;
        if (newValue >= PREVIEW_TOGGLE_CLICK_TIMES) {
            setIsLoading(true);
            togglePreview()
                .then(() => {
                pushNotification({
                    type: "WARNING",
                    title: formatMessage(i18n.previewSwitchNotficationSucceededTitle),
                    content: formatMessage(i18n.previewSwitchNotficationSucceededText),
                });
            })
                .catch((error) => {
                console.error(error);
                pushNotification({
                    type: "ERROR",
                    title: formatMessage(i18n.previewSwitchNotficationFailedTitle),
                    content: error.message,
                });
            })
                .finally(() => setIsLoading(false));
            newValue = 0;
        }
        setVersionClickAmount(newValue);
    }, [formatMessage, isLoading, pushNotification, togglePreview, versionClickAmount]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(Spinner, { size: 12, color: WHITE }),
        !isLoading && (React.createElement("div", { className: classNames(className, "qf-Label"), "data-cy": "gv-name-version", style: style, onClick: handleOnClick },
            productName,
            " ",
            trimPatchFromProductVersionIfReleaseVersion(version || ""),
            " ",
            isInPreview && React.createElement(React.Fragment, null,
                "(",
                formatMessage(i18n.preview),
                ")")))));
}
