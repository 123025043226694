import { user } from "../../../../helpers/apis";
import getProductEditionByApiString from "./getProductEditionByApiString";
import getProductEditionPremoByApiString from "./getProductEditionPremoByApiString";
export default function getContextData() {
    return user().then((data) => {
        var _a;
        if (!data)
            return undefined;
        return {
            version: data.versionName,
            isInPreview: data.preview || false,
            edition: getProductEditionByApiString(data.edition),
            editionPremo: getProductEditionPremoByApiString(data.premoEdition),
            additionalNavBarLinks: ((_a = data.links) === null || _a === void 0 ? void 0 : _a.map((link) => {
                const { name, url, target, i18nName } = link;
                return {
                    name: name || "",
                    url: url || "",
                    target: target || undefined,
                    i18nName: i18nName || undefined,
                };
            })) || [],
        };
    });
}
