import { EPriority, set as setInLs } from "@janitza/websdk-lib-localstorage";
import { user } from "../../../../helpers/apis";
import { LOCALSTORAGE_KEY_DEPRECATED } from "../../config";
import extractUserFromIUserObjectResponse from "./extractUserFromIUserObjectResponse";
export default function getContextData() {
    return user().then((data) => {
        if (!data)
            return undefined;
        setInLs(LOCALSTORAGE_KEY_DEPRECATED, data, { priority: EPriority.HIGH });
        return {
            isDefaultPasswordEnabled: data.defaultPassword || false,
            user: data.loggedIn ? extractUserFromIUserObjectResponse(data) : undefined,
            isLoggedIn: data.loggedIn || false,
        };
    });
}
