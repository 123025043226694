export const WHITE = "#FFFFFF";
export const JANITZA_BLUE_100 = "#4574A7";
export const JANITZA_BLUE_90 = "#5781AF";
export const JANITZA_BLUE_80 = "#6A90B9";
export const JANITZA_BLUE_70 = "#7C9DC1";
export const JANITZA_BLUE_60 = "#8FACCA";
export const JANITZA_BLUE_50 = "#A2B9D3";
export const JANITZA_BLUE_40 = "#B5C7DC";
export const JANITZA_BLUE_30 = "#C7D5E4";
export const JANITZA_BLUE_20 = "#DAE3ED";
export const JANITZA_BLUE_10 = "#ECF1F6";
export const JANITZA_BLUE_05 = "#F5F8FA";
export const JANITZA_BLUE_03 = "#F9FAFC";
export const CLOUD_BURST_100 = "#233A54";
export const CLOUD_BURST_90 = "#394D65";
export const CLOUD_BURST_80 = "#4F6176";
export const CLOUD_BURST_70 = "#657587";
export const CLOUD_BURST_60 = "#7B8998";
export const CLOUD_BURST_50 = "#919CA9";
export const CLOUD_BURST_40 = "#A7B0BB";
export const CLOUD_BURST_30 = "#BDC3CB";
export const CLOUD_BURST_20 = "#D3D8DD";
export const CLOUD_BURST_10 = "#E9EBED";
export const CLOUD_BURST_05 = "#F4F5F6";
export const CLOUD_BURST_03 = "#F8F9FA";
export const PUNCH_100 = "#DB3A34";
export const PUNCH_90 = "#DE4D48";
export const PUNCH_80 = "#E2615D";
export const PUNCH_70 = "#E57570";
export const PUNCH_60 = "#E98985";
export const PUNCH_50 = "#ED9C99";
export const PUNCH_40 = "#F1B0AE";
export const PUNCH_30 = "#F4C3C2";
export const PUNCH_20 = "#F8D8D6";
export const PUNCH_10 = "#FBEBEA";
export const PUNCH_05 = "#FDF5F5";
export const PUNCH_03 = "#FEF9F9";
export const LOCHINVAR_100 = "#278977";
export const LOCHINVAR_90 = "#3C9484";
export const LOCHINVAR_80 = "#52A192";
export const LOCHINVAR_70 = "#67AC9F";
export const LOCHINVAR_60 = "#7DB8AD";
export const LOCHINVAR_50 = "#93C4BB";
export const LOCHINVAR_40 = "#A9D0C9";
export const LOCHINVAR_30 = "#BEDBD6";
export const LOCHINVAR_20 = "#D4E7E4";
export const LOCHINVAR_10 = "#E9F3F1";
export const LOCHINVAR_05 = "#F4F9F8";
export const LOCHINVAR_03 = "#F8FBFB";
export const JELLY_BEAN_100 = "#276A89";
export const JELLY_BEAN_90 = "#3C7894";
export const JELLY_BEAN_80 = "#5288A1";
export const JELLY_BEAN_70 = "#6796AC";
export const JELLY_BEAN_60 = "#7DA6B8";
export const JELLY_BEAN_50 = "#93B4C4";
export const JELLY_BEAN_40 = "#A9C3D0";
export const JELLY_BEAN_30 = "#BED2DB";
export const JELLY_BEAN_20 = "#D4E1E7";
export const JELLY_BEAN_10 = "#E9F0F3";
export const JELLY_BEAN_05 = "#F4F7F9";
export const JELLY_BEAN_03 = "#F8FAFB";
export const SLATE_GRAY_100 = "#677C86";
export const SLATE_GRAY_90 = "#768992";
export const SLATE_GRAY_80 = "#85969E";
export const SLATE_GRAY_70 = "#94A3AA";
export const SLATE_GRAY_60 = "#A4B0B6";
export const SLATE_GRAY_50 = "#B3BDC2";
export const SLATE_GRAY_40 = "#C2CBCF";
export const SLATE_GRAY_30 = "#D1D7DA";
export const SLATE_GRAY_20 = "#E1E5E7";
export const SLATE_GRAY_10 = "#EFF1F2";
export const SLATE_GRAY_05 = "#F7F8F9";
export const SLATE_GRAY_03 = "#FAFBFB";
export const BLACK_100 = "#000000";
export const BLACK_90 = "#191919";
export const BLACK_80 = "#333333";
export const BLACK_70 = "#4C4C4C";
export const BLACK_60 = "#666666";
export const BLACK_50 = "#7F7F7F";
export const BLACK_40 = "#999999";
export const BLACK_30 = "#B2B2B2";
export const BLACK_20 = "#CCCCCC";
export const BLACK_10 = "#E5E5E5";
export const BLACK_05 = "#F2F2F2";
export const BLACK_03 = "#FAFAFA";
export const BRIGHT_SUN_100 = "#FACB40";
export const BRIGHT_SUN_90 = "#FAD053";
export const BRIGHT_SUN_80 = "#FBD566";
export const BRIGHT_SUN_70 = "#FBDA79";
export const BRIGHT_SUN_60 = "#FCE08C";
export const BRIGHT_SUN_50 = "#FCE59F";
export const BRIGHT_SUN_40 = "#FDEAB3";
export const BRIGHT_SUN_30 = "#FDEFC5";
export const BRIGHT_SUN_20 = "#FEF5D9";
export const BRIGHT_SUN_10 = "#FEF9EB";
export const BRIGHT_SUN_05 = "#FEFCF5";
export const BRIGHT_SUN_03 = "#FEFDFA";
export const GREEN_VOGUE_100 = "#011F5C";
export const GREEN_VOGUE_90 = "#1A356C";
export const GREEN_VOGUE_80 = "#344C7D";
export const GREEN_VOGUE_70 = "#4D628C";
export const GREEN_VOGUE_60 = "#67799D";
export const GREEN_VOGUE_50 = "#808FAD";
export const GREEN_VOGUE_40 = "#99A5BE";
export const GREEN_VOGUE_30 = "#B2BBCE";
export const GREEN_VOGUE_20 = "#CCD2DE";
export const GREEN_VOGUE_10 = "#E5E8EE";
export const GREEN_VOGUE_05 = "#F2F4F7";
export const GREEN_VOGUE_03 = "#F7F8FA";
export const PASTEL_GREEN_100 = "#7DDF64";
export const PASTEL_GREEN_90 = "#8AE273";
export const PASTEL_GREEN_80 = "#97E583";
export const PASTEL_GREEN_70 = "#A4E892";
export const PASTEL_GREEN_60 = "#B1ECA2";
export const PASTEL_GREEN_50 = "#BEEFB1";
export const PASTEL_GREEN_40 = "#CBF2C1";
export const PASTEL_GREEN_30 = "#D8F5D0";
export const PASTEL_GREEN_20 = "#E5F9E0";
export const PASTEL_GREEN_10 = "#F2FBEF";
export const PASTEL_GREEN_05 = "#F8FDF7";
export const PASTEL_GREEN_03 = "#FBFEFA";
export const FLUSH_ORANGE_100 = "#FF8600";
export const FLUSH_ORANGE_90 = "#FF9219";
export const FLUSH_ORANGE_80 = "#FF9E33";
export const FLUSH_ORANGE_70 = "#FFAA4C";
export const FLUSH_ORANGE_60 = "#FFB666";
export const FLUSH_ORANGE_50 = "#FFC27F";
export const FLUSH_ORANGE_40 = "#FFCF99";
export const FLUSH_ORANGE_30 = "#FFDAB2";
export const FLUSH_ORANGE_20 = "#FFE7CC";
export const FLUSH_ORANGE_10 = "#FFF2E5";
export const FLUSH_ORANGE_05 = "#FFF9F2";
export const FLUSH_ORANGE_03 = "#FFFBF7";
export const CHOCOLATE_BROWN_100 = "#8B572A";
export const CHOCOLATE_BROWN_90 = "#96673F";
export const CHOCOLATE_BROWN_80 = "#A27955";
export const CHOCOLATE_BROWN_70 = "#AD8969";
export const CHOCOLATE_BROWN_60 = "#B99A7F";
export const CHOCOLATE_BROWN_50 = "#C5AB94";
export const CHOCOLATE_BROWN_40 = "#D1BCAA";
export const CHOCOLATE_BROWN_30 = "#DCCCBF";
export const CHOCOLATE_BROWN_20 = "#E8DDD4";
export const CHOCOLATE_BROWN_10 = "#F3EEE9";
export const CHOCOLATE_BROWN_05 = "#F9F6F4";
export const CHOCOLATE_BROWN_03 = "#FBFAF8";
export const MEDIUM_GROUP_COMPRESSED_AIR = "#57C5C2";
export const MEDIUM_GROUP_COOLING = "#6DD9E5";
export const MEDIUM_GROUP_ELECTRICITY = "#F37272";
export const MEDIUM_GROUP_FLUID = "#889BD8";
export const MEDIUM_GROUP_FUEL = "#CDA487";
export const MEDIUM_GROUP_GAS = "#E9C211";
export const MEDIUM_GROUP_HEAT = "#9E4D4D";
export const MEDIUM_GROUP_HEATING_OIL = "#C57C88";
export const MEDIUM_GROUP_HYDROGEN = "#77CE84";
export const MEDIUM_GROUP_LUBRICANT = "#B69022";
export const MEDIUM_GROUP_SOLID_FUEL = "#B77E44";
export const MEDIUM_GROUP_WATER = "#5F93C3";
export const COLOR_PICKER_DEFAULTS = [
    JANITZA_BLUE_100,
    "#45C1FF",
    "#36D8B7",
    "#7CD030",
    "#F1CA33",
    "#FF7A29",
    "#FF2950",
    "#9545A7",
];
