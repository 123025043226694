import { DEFAULT_PRIORITY } from "../config";
export default function set(storage, key, value, options) {
    var priority = (options === null || options === void 0 ? void 0 : options.priority) || DEFAULT_PRIORITY;
    var isFireEventForSameTab = (options === null || options === void 0 ? void 0 : options.fireEventForSameTab) || false;
    var isRet = storage.set(key, value, priority);
    if (isFireEventForSameTab) {
        // To trigger localstorage listeners on the same tab/window, we fire a new event on the key
        var event_1 = new StorageEvent("storage");
        event_1.initStorageEvent("storage", false, false, key);
        window.dispatchEvent(event_1);
    }
    return isRet;
}
