import React, { useCallback, useMemo, useState } from "react";
import ModalsContext from "./ModalsContext";
import { generateUniqueId } from "../../helpers";
export default function ModalsProvider({ children }) {
    const [modals, setModals] = useState([]);
    const removeById = useCallback((id) => setModals((old) => old.filter((entry) => entry.id !== id)), []);
    const [portalRoot, setPortalRoot] = useState();
    // Removes the last n elements
    const pop = useCallback((amount = 1) => {
        const newEntries = Array.from(modals);
        for (let i = 0; i <= amount; i++)
            newEntries.pop(); // eslint-disable-line no-plusplus
        setModals(newEntries);
        return newEntries.length;
    }, [modals]);
    const push = useCallback((meta) => {
        const id = generateUniqueId();
        const metaWithDefaults = {
            id,
            content: meta.content,
            isSimplyClosable: meta.isSimplyClosable || true,
            doesCoverContainer: meta.doesCoverContainer || false,
            onClose: meta.onClose || (() => removeById(id)),
        };
        setModals((old) => [...old, metaWithDefaults]);
        return metaWithDefaults;
    }, [removeById]);
    // Removes the first n elements
    const shift = useCallback((amount = 1) => {
        const newEntries = Array.from(modals);
        for (let i = 0; i <= amount; i++)
            newEntries.shift(); // eslint-disable-line no-plusplus
        setModals(newEntries);
        return newEntries.length;
    }, [modals]);
    const clear = useCallback(() => setModals([]), []);
    const context = useMemo(() => {
        return {
            modals,
            push,
            pop,
            shift,
            clear,
            removeById,
            portalRoot,
            setPortalRoot,
        };
    }, [clear, modals, pop, push, shift, removeById, portalRoot]);
    return React.createElement(ModalsContext.Provider, { value: context }, children);
}
