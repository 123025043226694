import { defineMessages } from "react-intl";
export default defineMessages({
    preview: {
        id: "container.footer.preview",
        defaultMessage: "Preview",
        description: "[WebSDK Container] Text behind the product version being shown when the product is in preview modus",
    },
    previewSwitchNotficationFailedTitle: {
        id: "container.footer.switchPreviewStable.notifiation.failed.title",
        defaultMessage: "Unable to switch mode",
        description: "[WebSDK Container] Notitification title on fail on switching mode.",
    },
    previewSwitchNotficationSucceededTitle: {
        id: "container.footer.switchPreviewStable.notifiation.succeeded.title",
        defaultMessage: "Succesfully switched mode",
        description: "[WebSDK Container] Notitification title on success on switching mode.",
    },
    previewSwitchNotficationSucceededText: {
        id: "container.footer.switchPreviewStable.notifiation.succeeded.text",
        defaultMessage: "The server must now be restarted manually.",
        description: "[WebSDK Container] Notitification text on success on switching mode.",
    },
});
