import React, { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import validator from "validator";
import { Spinner } from "@janitza/ui";
import { BLACK_100 } from "@janitza/websdk-lib-colors";
import styles from "./LoginModalWindow.module.scss";
import LegacyModalWindow from "../LegacyModalWindow/LegacyModalWindow";
import i18n from "./LoginModalWindow.i18n";
import LegacyModalWindowTable from "../LegacyModalWindowTable/LegacyModalWindowTable";
import LegacyModalWindowButton from "../LegacyModalWindowButton/LegacyModalWindowButton";
import { ModalsContext, NotificationsContext, UserContext } from "../../../contexts";
import { DEFAULT_USERNAME, DEFAULT_PASSWORD } from "../../../contexts/UserContext/config";
import ForgotPasswordModalWindow from "../ForgotPasswordModalWindow/ForgotPasswordModalWindow";
export default function LoginModalWindow({ onSuccess, onClose, className, style }) {
    const { formatMessage } = useIntl();
    const { login, isDefaultPasswordEnabled, lastUsername } = useContext(UserContext);
    const { push: pushNotification } = useContext(NotificationsContext);
    const { push: pushModal, pop: popModal } = useContext(ModalsContext);
    const [username, setUsername] = useState(lastUsername || DEFAULT_USERNAME);
    const [password, setPassword] = useState(isDefaultPasswordEnabled ? DEFAULT_PASSWORD : "");
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = useCallback(() => {
        if (isLoading)
            return;
        if (validator.isEmpty(username) || validator.isEmpty(password))
            return;
        setIsLoading(true);
        login(username, password)
            .then(() => {
            pushNotification({ type: "OK", title: formatMessage(i18n.loginSuccessfull), content: "" });
            if (onSuccess)
                onSuccess();
        })
            .catch((error) => {
            console.error(error);
            pushNotification({ type: "ERROR", title: formatMessage(i18n.loginFailed), content: error.message });
        })
            .finally(() => setIsLoading(false));
    }, [isLoading, formatMessage, login, onSuccess, password, pushNotification, username]);
    const footerLeft = (React.createElement(LegacyModalWindowButton, { icon: "KEY_QUESTION", onClick: () => {
            pushModal({
                content: React.createElement(ForgotPasswordModalWindow, { onSuccess: popModal, onClose: popModal }),
                doesCoverContainer: true,
                isSimplyClosable: true,
            });
        } }, formatMessage(i18n.forgotYourPassword)));
    const footerRight = (React.createElement(React.Fragment, null,
        isLoading && React.createElement(Spinner, { color: BLACK_100, style: { margin: "0 20px" } }),
        !isLoading && (React.createElement(LegacyModalWindowButton, { icon: "KEY", onClick: onSubmit }, formatMessage(i18n.login))),
        React.createElement(LegacyModalWindowButton, { icon: "CROSS", onClick: onClose }, formatMessage(i18n.close))));
    return (React.createElement(LegacyModalWindow, { footerLeft: footerLeft, footerRight: footerRight, onClose: onClose, className: classNames(styles.window, className), style: style },
        React.createElement("form", null,
            React.createElement(LegacyModalWindowTable, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 2 },
                            React.createElement("div", { className: styles.headerLine }),
                            formatMessage(i18n.loginLabel)))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            formatMessage(i18n.usernameLabel),
                            ":"),
                        React.createElement("td", null,
                            React.createElement("input", { type: "text", name: "username", autoComplete: "on", className: classNames(styles.input, validator.isEmpty(username) && styles.isInvalid, "qf-TextField"), value: username, placeholder: formatMessage(i18n.username), onChange: (event) => setUsername(event.target.value), onKeyPress: (event) => {
                                    if (event.key === "Enter")
                                        onSubmit();
                                } }))),
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            formatMessage(i18n.passwordLabel),
                            ":"),
                        React.createElement("td", null,
                            React.createElement("input", { type: "password", name: "password", autoComplete: "on", className: classNames(styles.input, validator.isEmpty(password) && styles.isInvalid, "qf-TextField"), value: password, placeholder: "\u25CF\u25CF\u25CF\u25CF", onChange: (event) => setPassword(event.target.value), onKeyPress: (event) => {
                                    if (event.key === "Enter")
                                        onSubmit();
                                } }))))))));
}
