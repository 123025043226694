import React, { useCallback, useMemo, useState } from "react";
import { OEM_TOKEN, OEM_NAME, OEM_FULL_NAME, OEM_HOMEPAGE, GRIDVIS_NAME } from "@janitza/websdk-lib-oem";
import ProductContext from "./ProductContext";
import { INVALIDATION_INTERVAL_MS, LOCALSTORAGE_KEY } from "./config";
import { getContextDataFromLsOrApi, useUpdateContextStateContiniously, isProductEditionAtLeast, isProductEditionPremoAtLeast, isProductVersionAtLeast, getMapFromRecord, writeToLocalstorage, } from "../helpers";
import { getContextData as getContextDataFromApi } from "./helpers";
import { switchToStable as switchToStableRest, switchToPreview as switchToPreviewRest } from "../../helpers";
export default function ProductProvider({ children }) {
    const name = GRIDVIS_NAME;
    const manufacturer = useMemo(() => {
        return {
            token: OEM_TOKEN,
            name: OEM_NAME,
            fullName: OEM_FULL_NAME,
            homepageUrl: OEM_HOMEPAGE,
        };
    }, []);
    const [version, setVersion] = useState();
    const [isInPreview, setIsInPreview] = useState(false);
    const [edition, setEdition] = useState();
    const [editionPremo, setEditionPremo] = useState();
    const [additionalNavBarLinks, setAdditionalNavBarLinks] = useState([]);
    const isPremoEnabled = useMemo(() => !!editionPremo, [editionPremo]);
    const sanitizedVersion = useMemo(() => (version === null || version === void 0 ? void 0 : version.replace(/ .*/, "")) || undefined, [version]);
    const majorVersion = useMemo(() => (version ? Number.parseInt(version === null || version === void 0 ? void 0 : version.replace(/ .*/, ""), 10) : undefined), [version]);
    const updateState = useCallback(() => {
        getContextDataFromLsOrApi(LOCALSTORAGE_KEY, getContextDataFromApi)
            .then((data) => {
            if (data) {
                setVersion(data.version);
                setIsInPreview(data.isInPreview);
                setEdition(data.edition);
                setEditionPremo(data.editionPremo);
                setAdditionalNavBarLinks(data.additionalNavBarLinks);
            }
            else {
                setVersion(undefined);
                setIsInPreview(false);
                setEdition(undefined);
                setEditionPremo(undefined);
                setAdditionalNavBarLinks([]);
            }
        })
            .catch(console.error);
    }, []);
    const invalidateLocalStorage = useCallback(() => writeToLocalstorage(LOCALSTORAGE_KEY, undefined), []);
    const isVersionAtLeast = useCallback((vers) => isProductVersionAtLeast(vers, version), [version]);
    const isEditionAtLeast = useCallback((ed) => !isPremoEnabled && isProductEditionAtLeast(ed, edition), [edition, isPremoEnabled]);
    const isEditionPremoAtLeast = useCallback((ed) => isPremoEnabled && isProductEditionPremoAtLeast(ed, editionPremo), [editionPremo, isPremoEnabled]);
    const switchToPreview = useCallback(() => switchToPreviewRest().then(() => {
        invalidateLocalStorage();
    }), [invalidateLocalStorage]);
    const switchToStable = useCallback(() => switchToStableRest().then(() => {
        invalidateLocalStorage();
    }), [invalidateLocalStorage]);
    const togglePreview = useCallback(() => {
        if (isInPreview)
            return switchToStable();
        return switchToPreview();
    }, [isInPreview, switchToPreview, switchToStable]);
    useUpdateContextStateContiniously(updateState, LOCALSTORAGE_KEY, INVALIDATION_INTERVAL_MS);
    const context = useMemo(() => {
        return {
            name,
            manufacturer,
            version,
            sanitizedVersion,
            majorVersion,
            isInPreview,
            isPremoEnabled,
            edition,
            editionPremo,
            additionalNavBarLinks: additionalNavBarLinks.map((link) => {
                return Object.assign(Object.assign({}, link), { i18nName: link.i18nName ? getMapFromRecord(link.i18nName) : undefined });
            }),
            isVersionAtLeast,
            isEditionAtLeast,
            isEditionPremoAtLeast,
            switchToPreview,
            switchToStable,
            togglePreview,
        };
    }, [
        name,
        manufacturer,
        version,
        sanitizedVersion,
        majorVersion,
        isInPreview,
        isPremoEnabled,
        edition,
        editionPremo,
        additionalNavBarLinks,
        isVersionAtLeast,
        isEditionAtLeast,
        isEditionPremoAtLeast,
        switchToPreview,
        switchToStable,
        togglePreview,
    ]);
    return React.createElement(ProductContext.Provider, { value: context }, children);
}
