import React, { useCallback, useMemo, useState } from "react";
import FeatureTogglesContext from "./FeatureTogglesContext";
import { INVALIDATION_INTERVAL_MS, LOCALSTORAGE_KEY } from "./config";
import { getContextData as getDataFromApi } from "./helpers";
import { getContextDataFromLsOrApi, getMapFromRecord, useUpdateContextStateContiniously } from "../helpers";
export default function FeatureTogglesProvider({ children }) {
    const [featureToggles, setFeatureToggles] = useState();
    const updateState = useCallback(() => {
        getContextDataFromLsOrApi(LOCALSTORAGE_KEY, getDataFromApi)
            .then((data) => {
            if (data) {
                setFeatureToggles(data.featureToggles);
            }
            else {
                setFeatureToggles({});
            }
        })
            .catch(console.error);
    }, []);
    const isFeatureToggleEnabled = useCallback((featureToggleId) => {
        if (featureToggleId.includes("*")) {
            throw new Error("Wildcards in toggle validation are not supported. Use exact name instead.");
        }
        if (!featureToggles)
            return false;
        const isToggleEnabled = getMapFromRecord(featureToggles).get(featureToggleId);
        if (isToggleEnabled === undefined) {
            console.warn(`The used feature toggle '${featureToggleId}' was not found.`);
            return false;
        }
        return isToggleEnabled;
    }, [featureToggles]);
    useUpdateContextStateContiniously(updateState, LOCALSTORAGE_KEY, INVALIDATION_INTERVAL_MS);
    const context = useMemo(() => {
        return {
            featureToggles: featureToggles ? getMapFromRecord(featureToggles) : undefined,
            isFeatureToggleEnabled,
        };
    }, [featureToggles, isFeatureToggleEnabled]);
    return React.createElement(FeatureTogglesContext.Provider, { value: context }, children);
}
