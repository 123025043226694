import React from "react";
import styles from "./ImgTextBox.module.scss";

interface IProps {
  caption: React.ReactNode;
  children: React.ReactNode;
  imgSrc: string;
  altText: string;
  theme?: "light" | "dark";
}

export default function ImgTextBox({ caption, children, imgSrc, altText, theme = "dark" }: IProps): React.ReactElement {
  const background = theme === "light" ? "#FDFEFF" : "#F4F5F6FF";
  return (
    <div className={styles.root}>
      <div className={styles.imgBox} style={{ background }}>
        <img src={imgSrc} alt={altText} />
      </div>
      <div className={styles.caption}>{caption}</div>
      <p>{children}</p>
    </div>
  );
}
