import React, { useCallback, useMemo, useState } from "react";
import Timeout from "smart-timeout";
import NotificationsContext from "./NotificationsContext";
import { DEFAULT_NOTIFICATION_TIMEOUT_MS } from "./config";
import { generateUniqueId } from "../../helpers";
export default function NotificationsProvider({ children }) {
    const [notifications, setNotifications] = useState([]);
    const removeById = useCallback((id) => setNotifications((old) => old.filter((entry) => entry.id !== id)), []);
    const push = useCallback((meta) => {
        var _a;
        const id = generateUniqueId();
        const timeoutMs = meta.timeoutMs !== undefined ? meta.timeoutMs : DEFAULT_NOTIFICATION_TIMEOUT_MS;
        const timeout = timeoutMs !== 0 ? Timeout.instantiate(() => removeById(id), timeoutMs) : undefined;
        const metaWithDefaults = {
            id,
            type: meta.type || "INFO",
            title: meta.title,
            content: meta.content,
            isClosable: (_a = meta === null || meta === void 0 ? void 0 : meta.isClosable) !== null && _a !== void 0 ? _a : true,
            timeoutMs,
            timeout,
        };
        setNotifications((old) => [...old, metaWithDefaults]);
        return metaWithDefaults;
    }, [removeById]);
    const pop = useCallback((amount = 1) => {
        const newEntries = Array.from(notifications);
        for (let i = 0; i <= amount; i++)
            newEntries.pop(); // eslint-disable-line no-plusplus
        setNotifications(newEntries);
    }, [notifications]);
    const shift = useCallback((amount = 1) => {
        const newEntries = Array.from(notifications);
        for (let i = 0; i <= amount; i++)
            newEntries.shift(); // eslint-disable-line no-plusplus
        setNotifications(newEntries);
    }, [notifications]);
    const clear = useCallback(() => setNotifications([]), []);
    const pauseTimeoutForNotification = useCallback((id) => {
        var _a;
        const notification = notifications.find((entry) => entry.id === id);
        if (!notification)
            return;
        (_a = notification.timeout) === null || _a === void 0 ? void 0 : _a.pause();
    }, [notifications]);
    const continueTimeoutForNotification = useCallback((id) => {
        var _a;
        const notification = notifications.find((entry) => entry.id === id);
        if (!notification)
            return;
        (_a = notification.timeout) === null || _a === void 0 ? void 0 : _a.resume();
    }, [notifications]);
    const refreshNotifications = useCallback(() => {
        setNotifications((old) => old.filter((entry) => entry.timeout === undefined || entry.timeout.remaining() > 0));
    }, []);
    const context = useMemo(() => {
        return {
            notifications,
            push,
            pop,
            shift,
            clear,
            removeById,
            pauseTimeoutForNotification,
            continueTimeoutForNotification,
            refreshNotifications,
        };
    }, [
        clear,
        continueTimeoutForNotification,
        notifications,
        pauseTimeoutForNotification,
        pop,
        push,
        refreshNotifications,
        removeById,
        shift,
    ]);
    return React.createElement(NotificationsContext.Provider, { value: context }, children);
}
