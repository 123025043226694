import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import ModalWindow from "../ModalWindow/ModalWindow";
import i18n from "./ReleaseNotesModalWindow.i18n";
export default function ReleaseNotesModalWindow({ iframeUrl, onClose, className, style }) {
    const { formatMessage, locale } = useIntl();
    const url = iframeUrl || `/help/${locale}/releasenotes.html`;
    return (React.createElement(ModalWindow, { header: formatMessage(i18n.releaseNotes), onClose: onClose, className: classNames(className), style: style },
        React.createElement("iframe", { src: url })));
}
