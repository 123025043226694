import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import classNames from "classnames";
import styles from "./Notification.module.scss";
function NotificationIcon({ type }) {
    switch (type) {
        case "INFO":
            return React.createElement(FontAwesomeIcon, { icon: faInfoCircle, className: styles.icon });
        case "OK":
            return React.createElement(FontAwesomeIcon, { icon: faCheck, className: styles.icon });
        case "WARNING":
            return React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, className: styles.icon });
        case "ERROR":
            return React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, className: styles.icon });
        default:
            throw new Error(`Unable to determine notification icon for unknown NotificationType '${JSON.stringify(type)}'.`);
    }
}
export default function Notification({ type, title, content, footer, onMouseOver, onMouseOut, onClose, className, style, }) {
    return (React.createElement("div", { className: classNames(styles.notification, styles[type || "INFO"], className), onMouseOver: onMouseOver, onMouseOut: onMouseOut, style: style },
        React.createElement("div", { className: styles.iconPanel },
            React.createElement(NotificationIcon, { type: type || "INFO" })),
        React.createElement("div", { className: styles.titleAndContent },
            title && React.createElement("div", { className: styles.title }, title),
            React.createElement("div", { className: styles.content }, content),
            footer && React.createElement("div", { className: styles.footer }, footer)),
        React.createElement("div", { className: styles.closeBtnPanel }, onClose && (React.createElement("div", { className: styles.closeBtn, onClick: onClose },
            React.createElement(FontAwesomeIcon, { icon: faTimes, className: styles.closeBtnIcon }))))));
}
