import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { ClickOutsideListener } from "@janitza/ui";
import Menu from "../Menu/Menu";
import styles from "./NavBar.module.scss";
import useNavBarEntries from "./useNavBarEntries";
import { ProductContext, UserContext } from "../../../../../contexts";
import { NavBarEntry } from "./components";
function getHomeURL(startDashboard, locale) {
    if (startDashboard) {
        if (startDashboard.includes("_")) {
            // then it's a new Dashboard
            return `/dashboards/#/${startDashboard}`;
        }
        return `/energy/${locale}/dashboard.html#${startDashboard}`;
    }
    return `/energy/${locale}`;
}
export default function NavBar({ className, style }) {
    const { locale } = useIntl();
    const { additionalNavBarLinks } = useContext(ProductContext);
    const { user } = useContext(UserContext);
    const [navBarEntries] = useNavBarEntries();
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const homeUrl = getHomeURL(user === null || user === void 0 ? void 0 : user.startDashboardId, locale);
    return (React.createElement("div", { className: classNames(styles.navBar, className, "qf-List"), style: style, "data-cy": "Navbar" },
        React.createElement("div", { className: classNames(styles.entry, styles.menu) },
            React.createElement(ClickOutsideListener, { onClickOutside: () => setIsMenuVisible(false), disabled: !isMenuVisible },
                React.createElement("a", { onClick: () => setIsMenuVisible(!isMenuVisible), "data-cy": "MenuButton", className: classNames(styles.button, "qf-Button") }),
                isMenuVisible && React.createElement(Menu, { className: styles.dropdownMenu, onClose: () => setIsMenuVisible(false) }))),
        React.createElement("div", { className: classNames(styles.entry, styles.home) },
            React.createElement("a", { href: homeUrl, "data-cy": "Home", className: classNames(styles.button, "qf-ListItem") })),
        navBarEntries
            .filter((entry) => entry.isShown)
            .map((entry) => {
            return React.createElement(NavBarEntry, { key: entry.id, entry: entry, className: styles.entry });
        }),
        additionalNavBarLinks
            .map((entry) => {
            var _a;
            const translatedName = (_a = entry.i18nName) === null || _a === void 0 ? void 0 : _a.get(locale);
            // GRIDVIS-9995: Legacy energy links (ILinkProvider.java, e.g. BMW)
            const href = entry.url.includes("iframe.html") ? `/energy/${locale}/${entry.url}` : entry.url;
            const navBarEntry = {
                id: entry.url,
                name: translatedName || entry.name,
                href,
                target: entry.target,
                isShown: true,
            };
            return navBarEntry;
        })
            .filter((entry) => entry.isShown)
            .map((entry) => (React.createElement(NavBarEntry, { key: entry.id, entry: entry, className: styles.entry })))));
}
