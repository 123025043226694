import { useEffect, useRef } from "react";
import { coerceRefObject } from "../../helpers";
function coerceOptions(options) {
    return typeof options === "object" ? options : { shortOptions: options };
}
export function useEventListener(target, eventName, listener, options) {
    // Wrap the listener with a ref to keep a stable reference of the callback function used in the effect:
    const listenerRef = useRef(listener);
    listenerRef.current = listener;
    // The following properties are directly passed to the native browser API and are thus not renamed according to eslint:
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { shortOptions, capture, disabled, once, passive, signal } = coerceOptions(options);
    const isDisabled = disabled || listener == null;
    useEffect(() => {
        if (isDisabled)
            return undefined;
        const targetElement = coerceRefObject(target);
        if (targetElement == null)
            return undefined;
        const listenerOptions = shortOptions !== null && shortOptions !== void 0 ? shortOptions : { capture, once, passive, signal };
        const targetListener = (event) => { var _a; return (_a = listenerRef.current) === null || _a === void 0 ? void 0 : _a.call(listenerRef, event); };
        targetElement.addEventListener(eventName, targetListener, listenerOptions);
        return () => targetElement.removeEventListener(eventName, targetListener, listenerOptions);
    }, [capture, eventName, isDisabled, once, passive, shortOptions, signal, target]);
}
