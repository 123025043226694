import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "./Menu.module.scss";
import i18n from "./Menu.i18n";
import useMenuEntries from "./useMenuEntries";
import { MenuEntry } from "./components";
export default function Menu({ onClose, className, style }) {
    const { formatMessage } = useIntl();
    const [menuEntries] = useMenuEntries();
    return (React.createElement("ul", { className: classNames(styles.menu, className, "qf-List"), style: style, "data-cy": "Navigation" },
        React.createElement("li", { className: styles.menuHeader }, formatMessage(i18n.navigation)),
        menuEntries
            .filter((entry) => entry.isShown)
            .map((entry) => (React.createElement(MenuEntry, { key: entry.id, entry: entry, onClose: onClose })))));
}
