import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "./ErrorMessageScreen.module.scss";
import i18n from "./ErrorMessageScreen.i18n";
export default function ErrorMessageScreen({ error, errorInfo, showDetails, errorMessage, className, style, }) {
    const { formatMessage } = useIntl();
    return (React.createElement("div", { className: classNames(styles.screen, className), style: style },
        React.createElement("div", { className: classNames(styles.window) },
            errorMessage || formatMessage(i18n.unexpectedErrorOccurred),
            showDetails && error && errorInfo && (React.createElement("details", null,
                error.toString(),
                errorInfo.componentStack)))));
}
