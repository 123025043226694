import React from "react";
import styles from "../Content.module.scss";
import TitleElement from "./TitleElement/TitleElement";
import ImgTextBox from "./ImgTextBox/ImgTextBox";
import TextBox from "./TextBox/TextBox";

// images
import Hero from "../../../img_9_DE/mainscreen_DE.png";

// images language specific
import ConsumptionAnaysis from "../../../img_9_DE/Verbrauchsanalyse_DE.png";
import HierarchyUser from "../../../img_9_DE/HierarchyUser_DE.png";
import CustomTemplate from "../../../img_9_DE/EigeneVorlagen_DE_1.png";
import GridVisTemplate from "../../../img_9_DE/GridVisTemplate_DE_2.png";
import Dashboard from "../../../img_9_DE/Dashboard_DE.png";
import DeviceDashboard from "../../../img_9_DE/Geraet_Dashboard_DE.png";
import HarmonicsObject from "../../../img_9_DE/Oberschwingungen_DE.png";
import BarchartObject from "../../../img_9_DE/Balkendiagramm_DE.png";

export default function ContentDE(): React.ReactElement {
  return (
    <div className={styles.content}>
      <div className={styles.heroWrapper}>
        <header>
          <TitleElement />
          <div className={styles.subtitle}>
            Die neue Übersicht: Optimierte <strong>Listenfunktion</strong> und
            <br />
            ein <strong>Hierarchiebaum</strong> für alle Benutzer
          </div>
        </header>
        <img className={styles.heroImg} src={Hero} alt="Listenfunktion Screenshot" />
        <div className={styles.backdrop} />
      </div>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Neue <strong>Listenfunktion</strong> und erweiterte <strong>Hierarchieverwaltung</strong>
            <br />
            Das sind die <strong>Highlights</strong>
          </div>
          <div className={styles.subcaption}>
            Die neue Listenfunktion bringt Verbesserungen in Benutzerfreundlichkeit und Performance
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={HierarchyUser}
              caption="Verbesserte Listenfunktion und erweiterte Hierarchieverwaltung"
              altText="Listenfunktion"
            >
              Unsere überarbeitete Listenfunktion bringt zahlreiche Optimierungen mit sich. Dabei steht die
              Benutzerfreundlichkeit im Vordergrund. Die erweiterte Hierarchieverwaltung sticht besonders heraus: Sie
              ermöglicht es, erstellte Hierarchien für alle GridVis Benutzer zugänglich zu machen und bietet zudem einen
              privaten Bereich für eine individuelle Gestaltungen mit einer eigenen Ordnerstruktur.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={ConsumptionAnaysis}
              caption="Aussagekräftige Verbrauchsanalyse: Summen in der Liste anzeigen"
              altText="Verbrauchsanalyse"
            >
              Wir haben die Verbrauchsanalyse in der Listenfunktion überarbeitet. Gewinnen Sie mühelos einen
              detaillierten Überblick über die Summen der Energieverbräuche in Ihrer Liste. Um zur Verbrauchsanalyse zu
              gelangen, fügen Sie einfach den gewünschten Energieverbrauch als separate Spalte hinzu und aktivieren Sie
              die Summenanzeige. Sie haben die Flexibilität, so viele Zeiträume in eine Liste einzufügen, wie Sie
              benötigen. Mit Hilfe der Selektionspalte können Sie eine “Was wäre, wenn”-Analyse betreiben. Ein starker
              einfacher Zugriff auf wichtige Energiedaten! Die Funktion ist ab der Expert Edition verfügbar.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={DeviceDashboard}
              caption="Dashboards direkt aus einer Liste in der Übersicht öffnen"
              altText="Gerätedashboard"
            >
              Mit dem neuen Dashboard Editor ab GridVis 9.0 haben wir das Erstellen von Dashboards erleichtert. In der
              neuesten Version 9.1 bieten wir Ihnen zusätzlichen Komfort: Öffnen Sie in der Übersicht Dashboards direkt
              aus einer Liste. Weisen Sie einfach einer oder mehreren Zeilen Dashboards zu und betrachten Sie diese
              unmittelbar in der Übersicht, ohne in die Dashboardverwaltung zu wechseln. Dabei können Sie flink zwischen
              Dashboards wechseln und deren Zeitdarstellung nach Bedarf anpassen. Ein praktisches und
              benutzerfreundliches Update für Ihre schnelle und einfache Datenanalyse!
            </ImgTextBox>
            <div className={styles.imgFlexWrapper}>
              <img src={Dashboard} className={styles.flexImage} alt="Übersicht Dashboard" />
            </div>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.darkWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            <strong>Vorlagen</strong> für Dashboards
          </div>
          <div className={styles.subcaption}>
            Neue <strong>vorgefertigte Vorlagen</strong> vereinfachen das Erstellen von Dashboards! Legen Sie eigene
            Vorlagen an, um die Dashboards noch <strong>individueller</strong> zu nutzen.
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={GridVisTemplate}
              caption="Vorgefertigte Vorlagen zum Erstellen von Dashboards"
              altText="GridVis Vorlagen"
              theme="light"
            >
              Mit unserem neuesten Release bieten wir Ihnen eine Auswahl an vorgefertigten Vorlagen, die den Prozess des
              Dashboard-Erstellens noch leichter machen. Nutzen Sie diese als Ausgangspunkt, passen Sie sie nach Ihren
              Wünschen an oder setzen Sie sie direkt in Ihren Projekten ein. Ein effektiver Weg, um Zeit zu sparen und
              produktiv zu sein.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={CustomTemplate}
              caption="Gestalten Sie Ihre eigenen Dashboard-Vorlagen"
              altText="Eigene Vorlagen"
              theme="light"
            >
              Abseits der von uns bereitgestellten Vorlagen haben Sie jetzt die Möglichkeit, individuelle Dashboards als
              Vorlagen zu speichern. Dies kann in den Dashboardeinstellungen vorgenommen werden. So behalten Sie stets
              den Überblick und können Ihre Projekte noch schneller starten.
            </ImgTextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Erweiterungen für <strong>Dashboards</strong> und <strong>Berichte</strong>
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.textImgRow}>
              <TextBox caption="Präzise Oberschwingungsanalyse: Das neue PQ-Objekt">
                Das neue PQ-Objekt &quot;Oberschwingungen (Säulendiagramm)&quot; erweitert das Portfolio an
                Visualisierungsobjekten für Dashboards und Berichte in GridVis. Mit diesem Tool können historische
                harmonische Oberschwingungen effizient visualisiert werden. Das Objekt stellt diese als Säulendiagramm
                dar, wodurch eine klare und anschauliche Übersicht der wichtigen PQ-Daten gewährleistet wird. Dieses
                Objekt bildet eine sinnvolle Ergänzung zu den bestehenden PQ-Objekten und trägt dazu bei, die gemessenen
                Netzqualitätsparameter besser zu visualisieren.
              </TextBox>
              <img className={styles.flexImage} src={HarmonicsObject} alt="Objekt Oberschwingungen" />
            </div>
            <div className={styles.textImgRow}>
              <img className={styles.flexImage} src={BarchartObject} alt="Objekt Balkendiagramm" />
              <TextBox caption="Neues Balkendiagramm zur schnellen Übersicht">
                Eine weitere Ergänzung im Bereich der Datenvisualisierung ist das neue Balkendiagramm, das Messwerte nun
                horizontal anzeigt. Im Unterschied zu herkömmlichen Säulendiagrammen ermöglicht diese horizontale
                Darstellung eine schnellere Erfassung der Daten, denn die dargestellten Werte werden absteigend
                sortiert. Es ist ideal für Anwendungen, bei denen es auf schnelle Einsicht und effektive Datenanalyse
                ankommt. Mit diesem Objekt können Anwender ihre Daten effizienter analysieren und informierte
                Entscheidungen schneller treffen.
              </TextBox>
            </div>
            <TextBox caption="Modbus Adresse vom Dashboard aus beschreiben">
              Mit unserem neuen &quot;Modbus schreiben&quot;-Objekt können Sie Parameter und Zustände direkt von Ihrem
              Dashboard aus in eine angegebene Modbus-Adresse schreiben. Das Besondere daran: Sie können damit nicht nur
              Messgeräte direkt parametrisieren, sondern auch Fehlerstrom Grenzwerte dynamisch anpassen oder sogar
              digitale Ausgänge schalten. All dies direkt von Ihrem Dashboard aus, was Ihnen eine gute Ergänzung in der
              Anlagensteuerung und -überwachung bietet. Dies kann entweder durch ein manuelles Eingabefeld oder mit
              vordefinierten Buttons erfolgen. Ein Klick auf einen solchen Button überträgt automatisch den festgelegten
              Wert. Dies eröffnet eine Vielzahl von Anwendungen, die zuvor so noch nicht realisierbar waren.
            </TextBox>
            <TextBox caption="Die multiple lineare Regression">
              Dank des neuen &quot;Multiple lineare Regression&quot;-Objekts können Sie nun noch tiefgreifendere
              Analysen durchführen. Mit diesem Werkzeug lässt sich beispielsweise der Energieverbrauch auf verschiedene
              Parameter untersuchen, um festzustellen, ob diese einen Einfluss auf den Energieverbrauch haben. Die
              ermittelten Abhängigkeiten werden übersichtlich in einer Tabelle dargestellt, sodass Sie stets den vollen
              Überblick behalten.
            </TextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapperMore}>
        <div className={styles.backdropReverse} />
        <div className={styles.sectionContent}>
          <div className={styles.caption} style={{ color: "#fff" }}>
            <strong>Weitere Funktionen im Schnellüberblick</strong>
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className={styles.heading}>Erweiterter Spannungsqualitätsreport nach IEEE519</div>
              <p>
                Der Spannungsqualitätsreport in GridVis wurde gemäß der aktuellen Norm IEEE 519 - 2022 aktualisiert.
                Dieser Datenexport, konfigurierbar in der GridVis Desktop-Anwendung, ermöglicht wie gewohnt auch eine
                Auswertung über das Web. Ein Merkmal dieses Reports ist die Möglichkeit, die Strom- und Spannungsanalyse
                unabhängig voneinander zu generieren, je nach Bedarf. Durch die Anpassung an die IEEE 519 - 2022 Norm
                gewährleistet der Datenexport eine konsistente und standardisierte Bewertung der Spannungsqualität, was
                eine optimale Überwachung und Steuerung Ihrer Anlagen unterstützt.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Optimierte Navigationsfunktion im Liniendiagramm</div>
              <p>
                Unsere Liniendiagramme auf den Dashboards bieten jetzt eine verbesserte Navigation durch die Einführung
                des &quot;Navigators&quot;. Diese Funktion, die in den Einstellungen aktiviert werden kann, ermöglicht
                es Ihnen, im Präsentationsmodus Ihres Dashboards intuitiv durch Zeitabschnitte zu navigieren. Vergrößern
                und verkleinern Sie beliebige Abschnitte, um tiefer in Ihre Messdaten einzutauchen und wichtige Details
                hervorzuheben. Diese neue Funktion erleichtert das Erkennen von Mustern und Abweichungen in Ihren Daten
                und unterstützt eine effiziente Analyse.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Erweiterte Linkfunktion für effiziente Dashboard-Nutzung</div>
              <p>
                Die Linkfunktion in unseren Dashboards wurde erweitert. Jedem Link kann ein spezifisches Gerät
                zugeordnet werden, dessen Daten beim Öffnen des Dashboards dargestellt werden. Diese Funktionalität
                eröffnet die Möglichkeit, direkt über einen Link aus einer Gesamtübersicht verschiedener Geräte in eine
                detaillierte Ansicht für ein ausgewähltes Messgerät zu wechseln. So können Sie mit wenig Aufwand ein
                Dashboard erstellen das immer wieder typische Messdaten eines Messgeräts darstellt.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.verticalSpace} />
      </section>
    </div>
  );
}
