import React, { useCallback, useMemo, useState } from "react";
import AlarmsContext from "./AlarmsContext";
import { INVALIDATION_INTERVAL_MS, LOCALSTORAGE_KEY } from "./config";
import { getContextDataFromLsOrApi, useUpdateContextStateContiniously } from "../helpers";
import { getContextData as getDataFromApi } from "./helpers";
export default function AlarmsProvider({ children }) {
    const [openAlarmsCount, setOpenAlarmsCount] = useState(0);
    const updateState = useCallback(() => {
        getContextDataFromLsOrApi(LOCALSTORAGE_KEY, getDataFromApi)
            .then((data) => {
            if (data) {
                setOpenAlarmsCount(data.openAlarmsCount);
            }
            else {
                setOpenAlarmsCount(0);
            }
        })
            .catch(console.error);
    }, []);
    useUpdateContextStateContiniously(updateState, LOCALSTORAGE_KEY, INVALIDATION_INTERVAL_MS);
    const context = useMemo(() => {
        return {
            openAlarmsCount,
        };
    }, [openAlarmsCount]);
    return React.createElement(AlarmsContext.Provider, { value: context }, children);
}
