import React from "react";
import { useIntl } from "react-intl";
import ContentEN from "./components/ContentEN";
import ContentES from "./components/ContentES";
import ContentDE from "./components/ContentDE";
import ContentIT from "./components/ContentIT";

/**
 * Inhalt der Landing-Page
 */
export default function Content(): React.ReactElement {
  const { locale } = useIntl();

  /* Nach Absprache mit Sebastian Römer habe ich diese Texte erst mal nicht per react-intl internationalisiert,
   * da hier der Prozess nicht geklärt ist, wie und von wem die Übersetzungen erstellt werden. Für Sebastian
   * ist der Workflow einfacher, wenn die Texte direkt im Quellcode liegen.
   */

  const dashIndex = locale.indexOf("-");
  const language = dashIndex < 0 ? locale : locale.substring(0, dashIndex);
  switch (language) {
    case "de":
      return <ContentDE />;
    case "es":
      return <ContentES />;
    case "it":
      return <ContentIT />;
    case "en":
      return <ContentEN />;
    default:
      return <ContentEN />; // TODO: should throw
  }
}
