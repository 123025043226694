"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_intl_1 = require("react-intl");
const constants_1 = require("../../constants");
const functions_1 = require("../../functions");
function IntlProvider({ messages: messagesProp, translations, children, locale: localeProp, defaultLocale, }) {
    const [locale, setLocale] = (0, react_1.useState)((0, functions_1.getLocale)());
    const messages = (0, react_1.useMemo)(() => (translations ? (0, functions_1.getMessagesForLocale)(translations, localeProp || locale) : messagesProp), [locale, localeProp, messagesProp, translations]);
    const localStorageListener = (0, react_1.useCallback)((event) => {
        if (event.key === constants_1.STORAGE_KEY_DESIRED_LOCALE_WITH_PREFIX)
            setLocale((0, functions_1.getLocale)());
    }, []);
    (0, react_1.useEffect)(() => window.addEventListener("storage", localStorageListener));
    return (react_1.default.createElement(react_intl_1.IntlProvider, { locale: localeProp || locale, defaultLocale: defaultLocale || (0, functions_1.getDefaultLocale)(), messages: messages }, children));
}
exports.default = IntlProvider;
