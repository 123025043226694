import { defineMessages } from "react-intl";
export default defineMessages({
    overview: {
        id: "Websdk.container.navbar.entry.overview.title",
        defaultMessage: "Overview",
        description: "[WebSDK Container] NavBar Title of the entry",
    },
    dashboards: {
        id: "Websdk.container.navbar.entry.dashboard.title",
        defaultMessage: "Dashboards",
        description: "[WebSDK Container] NavBar Title of the entry",
    },
    alarms: {
        id: "Websdk.container.navbar.entry.alarms.title",
        defaultMessage: "Alarms",
        description: "[WebSDK Container] NavBar Title of the entry",
    },
    reports: {
        id: "Websdk.container.navbar.entry.reports.title",
        defaultMessage: "Reports",
        description: "[WebSDK Container] NavBar Title of the entry reports",
    },
});
