import { defineMessages } from "react-intl";
export default defineMessages({
    languageTitleEn: {
        id: "container.header.language-selector.entry.name.en",
        defaultMessage: "English",
        description: "[WebSDK Container] language selection entry for English",
    },
    languageTitleDe: {
        id: "container.header.language-selector.entry.name.de",
        defaultMessage: "German",
        description: "[WebSDK Container] language selection entry for German",
    },
    languageTitleEs: {
        id: "container.header.language-selector.entry.name.es",
        defaultMessage: "Spanish",
        description: "[WebSDK Container] language selection entry for Spanish",
    },
    languageTitleIt: {
        id: "container.header.language-selector.entry.name.it",
        defaultMessage: "Italian",
        description: "[WebSDK Container] language selection entry for Italian",
    },
});
