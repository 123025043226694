import { EUserType } from "../../../../enums";
function getUserTypeByString(userTypeString) {
    switch (userTypeString) {
        case "normal":
        case "unknown":
            return EUserType.NORMAL;
        case "view":
            return EUserType.VIEW;
        case "admin":
        case "ADMIN":
            return EUserType.ADMIN;
        case "anonymous":
            return EUserType.ANONYMOUS;
        default:
            throw new Error(`Unknown userType '${JSON.stringify(userTypeString)}'.`);
    }
}
export default function extractUserFromIUserObjectResponse(userObject) {
    return {
        id: userObject.userId,
        type: getUserTypeByString(userObject.userType),
        locale: userObject.language || undefined,
        country: userObject.country || undefined,
        weekStart: userObject.weekStart != null ? userObject.weekStart : undefined,
        username: userObject.username,
        permissions: userObject.permissions,
        startDashboardId: userObject.startDashboard || undefined,
    };
}
