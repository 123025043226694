"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const websdk_lib_localstorage_1 = require("@janitza/websdk-lib-localstorage");
const constants_1 = require("../../constants");
function setDesiredCountry(desiredCountry) {
    const isRet = (0, websdk_lib_localstorage_1.set)(constants_1.STORAGE_KEY_DESIRED_COUNTRY, desiredCountry, { priority: websdk_lib_localstorage_1.EPriority.HIGH });
    // To trigger the localstorage listener on the same tab/window, we fire a new event on the locale key
    const event = new StorageEvent("storage");
    event.initStorageEvent("storage", false, false, constants_1.STORAGE_KEY_DESIRED_COUNTRY_WITH_PREFIX);
    window.dispatchEvent(event);
    return isRet;
}
exports.default = setDesiredCountry;
