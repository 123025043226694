import { get as getFromLs } from "@janitza/websdk-lib-localstorage";
import { LS_KEY_PREVENT_RELEASENOTES } from "../../config";
import { getData } from "./getterAndSetter";
export default function doShowReleaseNotes(currentVersion) {
    return getData().then((result) => {
        if (getFromLs(LS_KEY_PREVENT_RELEASENOTES) !== undefined)
            return false; // Do not show release notes if explicitly preventing
        if (!result || !result.version)
            return true; // Show initial release notes if current version is not in LS
        return result.version !== currentVersion;
    });
}
