"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const locale_1 = require("./locale");
/**
 * @deprecated Use getMessagesForLocale(translations, locale?) instead.
 */
function getMessagesForCurrentLocale(translations) {
    const locale = (0, locale_1.getLocale)();
    return translations[(0, locale_1.getIsoLocale)(locale)];
}
exports.default = getMessagesForCurrentLocale;
