import React from "react";
import classNames from "classnames";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CloseButton.module.scss";
import { EQFClassName } from "../../../enums";
export default function CloseButton({ onClick, dataCy = "closeButton", className = "", style, }) {
    return (React.createElement("button", { type: "button", className: classNames(styles.closeButton, className, EQFClassName.BUTTON), "data-cy": dataCy, onClick: onClick, style: style },
        React.createElement(FontAwesomeIcon, { icon: faTimes, className: styles.icon })));
}
