import React, { useContext } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import i18n from "./Footer.i18n";
import { ProductNameAndVersion } from "./components";
import { ProductContext } from "../../../contexts";
export default function Footer({ className, style }) {
    const { formatMessage } = useIntl();
    const { manufacturer } = useContext(ProductContext);
    const { homepageUrl, fullName } = manufacturer;
    return (React.createElement("div", { className: classNames(styles.footer, className), style: style },
        React.createElement("div", { className: classNames(styles.section, styles.left) }),
        React.createElement("div", { className: classNames(styles.section, styles.center) },
            React.createElement("div", { className: styles.element },
                React.createElement("a", { href: `https://${homepageUrl}`, target: "_blank", rel: "noopener noreferrer" }, homepageUrl))),
        React.createElement("div", { className: classNames(styles.section, styles.right) },
            React.createElement("div", { className: styles.element },
                React.createElement(ProductNameAndVersion, null),
                React.createElement("div", null, formatMessage(i18n.copyright, {
                    name: fullName,
                }))))));
}
