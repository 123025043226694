import { REDIRECT_AFTER_LOGIN_SEARCHPARAM } from "../../../config";
import { EUserType } from "../../../enums";
export default function redirectAfterLogin(locale, edition, mainProject, user) {
    const isUserAdmin = user && user.type === EUserType.ADMIN;
    const isServiceMisconfigured = !mainProject || !edition;
    const redirectAfterLoginSearchParam = new URLSearchParams(window.location.search).get(REDIRECT_AFTER_LOGIN_SEARCHPARAM) || undefined;
    if (redirectAfterLoginSearchParam) {
        window.location.href = redirectAfterLoginSearchParam;
    }
    else if (isUserAdmin && isServiceMisconfigured) {
        window.location.href = `/energy/${locale}/server-config.html`;
    }
    else if (user && user.startDashboardId) {
        const url = user.startDashboardId.includes("_")
            ? `/dashboards/#/${user.startDashboardId}`
            : `/energy/${locale}/dashboard.html#${user.startDashboardId}`;
        window.location.href = url;
    }
}
