import { sendPostRequest } from "../../helpers";
export default function forgotPassword(data) {
    const { locale, email } = data;
    const localeSanitized = locale.trim() !== "" ? locale : "en";
    const emailSanitized = email.trim() !== "" ? email : "undefined";
    return sendPostRequest(`/security/user/forgotPassword/${localeSanitized}/${emailSanitized}`, data, {
        withCredentials: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { "Content-Type": "text/plain" },
    })
        .then((result) => {
        if (result.status === 200)
            return result.data;
        throw new Error(result.data);
    })
        .catch((error) => {
        var _a;
        throw new Error(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || "");
    });
}
