import React from "react";
import { getMessagesForLocale, IntlProvider } from "@janitza/websdk-lib-translations";
import Container, { IntlProvider as IntlProviderContainer } from "@janitza/websdk-comp-spa-container";
import translations from "../../translations/translations.json";
import Content from "../Content/Content";

export default function App(): React.ReactElement {
  return (
    <IntlProvider messages={getMessagesForLocale(translations)}>
      <IntlProviderContainer>
        <Container>
          <Content />
        </Container>
      </IntlProviderContainer>
    </IntlProvider>
  );
}
