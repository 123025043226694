import { defineMessages } from "react-intl";
export default defineMessages({
    loginLabel: {
        id: "container.header.userPanel.loginModal.header.label",
        defaultMessage: "Login",
        description: "Login dialog, table header label",
    },
    usernameLabel: {
        id: "container.header.userPanel.loginModal.username.label",
        defaultMessage: "Username",
        description: "Login dialog, username label left next to input field",
    },
    passwordLabel: {
        id: "container.header.userPanel.loginModal.password.label",
        defaultMessage: "Password",
        description: "Login dialog, password label left next to input field",
    },
    username: {
        id: "container.header.userPanel.loginModal.username.input.placeholder",
        defaultMessage: "Username",
        description: "[WebSDK Container] Login dialog, username input placeholder",
    },
    forgotYourPassword: {
        id: "container.header.userPanel.loginModal.buttons.forgotPw.text",
        defaultMessage: "Forgot your password?",
        description: "[WebSDK Container] Login dialog, button text for performing passwort reset",
    },
    login: {
        id: "container.header.userPanel.loginModal.buttons.login.text",
        defaultMessage: "Login",
        description: "[WebSDK Container] Login dialog, button text for submitting login form",
    },
    close: {
        id: "container.header.userPanel.loginModal.buttons.close.text",
        defaultMessage: "Close",
        description: "[WebSDK Container] Login dialog, button text for closing the dialog",
    },
    loginSuccessfull: {
        id: "container.notification.login.successful.title",
        defaultMessage: "Login was successful",
        description: "[WebSDK Container] Notification: Login successful title",
    },
    loginFailed: {
        id: "container.notification.login.failed.title",
        defaultMessage: "Login failed",
        description: "[WebSDK Container] Notification: Login failed title",
    },
});
