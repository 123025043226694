import { sendPostRequest } from "../../helpers";
export default function switchToPreview() {
    return sendPostRequest("/features/switchToPreview", undefined, { withCredentials: true })
        .then((result) => {
        if (result.status === 200)
            return result.data;
        throw new Error(result.data);
    })
        .catch((error) => {
        var _a;
        throw new Error(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || "");
    });
}
