import { defineMessages } from "react-intl";
export default defineMessages({
    login: {
        id: "container.header.userPanel.loginButton.text",
        defaultMessage: "Login",
        description: "The clickable text seen within the header of the container which opens the login modal",
    },
    logout: {
        id: "container.header.userPanel.logoutButton.text",
        defaultMessage: "Logout",
        description: "The clickable text seen within the header of the container which logs out the user",
    },
    logoutSuccessfull: {
        id: "container.notification.logout.successful.title",
        defaultMessage: "Logout was successful",
        description: "[WebSDK Container] Notification: Logout successful title",
    },
    logoutFailed: {
        id: "container.notification.logout.failed.title",
        defaultMessage: "Logout failed",
        description: "[WebSDK Container] Notification: Logout failed title",
    },
    anonymousUser: {
        id: "container.header.userPanel.userName.text",
        defaultMessage: "Anonymous",
        description: "User DisplayName within header of the container for Users anonymously logged-in",
    },
});
