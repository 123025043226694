import { identity, chain, find, map } from "underscore";
import { DEFAULT_PRIORITY, PREFIX } from "../config";
import { EPriority } from "../enums";
import getNumericValueForPriority from "./helpers/getNumericValueForPriority";
function computeKey(key, priority, prefix) {
    if (prefix === void 0) { prefix = PREFIX; }
    return "".concat(prefix, "-").concat(getNumericValueForPriority(priority)).concat(key);
}
var Storage = /** @class */ (function () {
    function Storage() {
        this._storage = window.localStorage;
        this._sortedKeys = []; // cache for all keys of local storage sorted by priority
    }
    Storage.prototype._initSortedKeys = function () {
        var length = this._storage.length;
        if (length < 1) {
            this._sortedKeys = [];
            return;
        }
        this._sortedKeys = chain(length)
            .range() // create an array with indices from 0 to length
            // map to the value returned by local storage key func
            .map(this._storage.key.bind(this._storage))
            .sortBy(identity) // sort by identity
            .value() // exit wrapped object
            .map(function (obj) { return obj || undefined; });
    };
    Storage.prototype._removeLowestPriorityEntry = function () {
        if (this._storage.length === 0) {
            // local storage is empty
            this._sortedKeys = [];
            return false;
        }
        // remove first elements
        var key = this._sortedKeys[0];
        if (key)
            this._storage.removeItem(key);
        this._sortedKeys.shift();
        return true;
    };
    Storage.prototype._getString = function (key) {
        var _this = this;
        var mapped = map([EPriority.HIGH, EPriority.MEDIUM, EPriority.LOW], function (priority) {
            return _this._storage.getItem(computeKey(key, priority));
        }).map(function (obj) { return obj || undefined; });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO
        var found = find(mapped, identity);
        return found;
    };
    Storage.prototype._putString = function (priority, key, itemData) {
        if (this._storage) {
            this._initSortedKeys();
            var isRepeat = true;
            var internKey = computeKey(key, priority);
            while (isRepeat) {
                try {
                    this._storage.setItem(internKey, itemData);
                    isRepeat = false;
                    return true;
                }
                catch (error) {
                    isRepeat = this._removeLowestPriorityEntry();
                }
            }
        }
        return false;
    };
    Storage.prototype.get = function (key) {
        var found = this._getString(key);
        return found ? JSON.parse(found) : undefined;
    };
    Storage.prototype.set = function (key, value, priority) {
        if (priority === void 0) { priority = DEFAULT_PRIORITY; }
        if (value == null) {
            var internKey = computeKey(key, priority);
            this._storage.removeItem(internKey);
            return true;
        }
        return this._putString(priority, key, JSON.stringify(value));
    };
    return Storage;
}());
export default Storage;
