import React, { useContext } from "react";
import classNames from "classnames";
import Badge from "./components/Badge/Badge";
import styles from "./OpenAlarmsBadge.module.scss";
import { AlarmsContext } from "../../../../../../../contexts";
export default function OpenAlarmsBadge({ className, style }) {
    const { openAlarmsCount } = useContext(AlarmsContext);
    if (openAlarmsCount < 1)
        return null;
    return (React.createElement(Badge, { className: classNames(styles.badge, className), style: style }, openAlarmsCount));
}
