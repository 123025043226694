import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import styles from "./NavBarEntry.module.scss";
import OpenAlarmsBadge from "../OpenAlarmsBadge/OpenAlarmsBadge";
import { ModalsContext } from "../../../../../../../contexts";
import { ModalWindow } from "../../../../../../modalWindows";
export default function NavBarEntry({ entry, className, style }) {
    const isUrlActive = (url) => window.location.href.includes(url);
    const { name, href, target, onClick } = entry;
    const { push: pushModal, pop: popModal } = useContext(ModalsContext);
    const handleOnClick = useCallback((event) => {
        if (onClick) {
            event.preventDefault();
            onClick(event);
        }
        else if (target === "_modal") {
            event.preventDefault();
            pushModal({
                content: (React.createElement(ModalWindow, { header: name, onClose: () => popModal() },
                    React.createElement("iframe", { className: styles.iframe, src: href || "" }))),
            });
        }
    }, [href, name, onClick, popModal, pushModal, target]);
    return (React.createElement("div", { className: classNames(styles.entry, isUrlActive(href || "./") && styles.isActive, className), style: style },
        React.createElement("a", { href: href, target: target, onClick: handleOnClick, className: "qf-ListItem" }, name),
        entry.id === "alarms" && React.createElement(OpenAlarmsBadge, null)));
}
