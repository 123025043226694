import React from "react";
import styles from "../Content.module.scss";
import TitleElement from "./TitleElement/TitleElement";
import ImgTextBox from "./ImgTextBox/ImgTextBox";
import TextBox from "./TextBox/TextBox";

// images
import Hero from "../../../img_9_EN/mainscreen_EN.png";

// images language specific
import ConsumptionAnaysis from "../../../img_9_EN/Verbrauchsanalyse_EN.png";
import HierarchyUser from "../../../img_9_EN/HierarchyUser_EN.png";
import CustomTemplate from "../../../img_9_EN/EigeneVorlagen_EN_1.png";
import GridVisTemplate from "../../../img_9_EN/GridVisTemplate_EN_2.png";
import Dashboard from "../../../img_9_EN/Dashboard_EN.png";
import DeviceDashboard from "../../../img_9_EN/GeraetDashboard_EN.png";
import HarmonicsObject from "../../../img_9_EN/Harmonics_EN.png";
import BarchartObject from "../../../img_9_EN/Balkendiagramm_EN.png";

export default function ContentIT(): React.ReactElement {
  return (
    <div className={styles.content}>
      <div className={styles.heroWrapper}>
        <header>
          <TitleElement />
          <div className={styles.subtitle}>
            La nuova panoramica: con una funzionalità di elenco ottimizzata e un albero gerarchico per tutti gli utenti
          </div>
        </header>
        <img className={styles.heroImg} src={Hero} alt="Editor del cruscotto" />
        <div className={styles.backdrop} />
      </div>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}>
            Nuova funzione di elenco e gestione della gerarchia ampliata. Questi sono i momenti salienti
          </div>
          <div className={styles.subcaption}>
            La nuova funzione di elenco apporta miglioramenti in termini di facilità d&apos;uso e prestazioni
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={HierarchyUser}
              caption="Funzione di elenco migliorata e gestione della gerarchia ampliata "
              altText="Funzione di elenco"
            >
              La nostra funzione di elenco rivista presenta numerose ottimizzazioni, con un focus sulla facilità
              d&apos;uso. La gestione della gerarchia potenziata si distingue in particolare: consente di rendere
              accessibili a tutti gli utenti di GridVis le gerarchie create e offre anche un&apos;area privata per
              personalizzazioni individuali con una propria struttura a cartelle.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={ConsumptionAnaysis}
              caption="Analisi significativa dei consumi: visualizzare le somme nell'elenco "
              altText="Analisi dei consumi"
            >
              Abbiamo rivisto l&apos;analisi dei consumi nella funzione di elenco. Ottenete senza sforzo una panoramica
              dettagliata delle somme dei consumi energetici nella vostra lista. Per accedere all&apos;analisi dei
              consumi, aggiungete semplicemente il consumo energetico desiderato come colonna separata e attivate la
              visualizzazione delle somme. Avete la flessibilità di inserire nella lista quanti periodi temporali
              desiderate. Utilizzando la colonna di selezione, potete effettuare un&apos;analisi &quot;cosa succederebbe
              se&quot;. Un accesso potente e semplice ai dati energetici cruciali! Questa funzione è disponibile dalla
              versione Expert Edition in poi.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={DeviceDashboard}
              caption="Aprire le dashboard direttamente da un elenco nella panoramica "
              altText="Cruscotto del dispositivo"
            >
              Con il nuovo Editor di Dashboard a partire da GridVis 9.0, abbiamo semplificato la creazione di dashboard.
              Nella versione più recente 9.1, offriamo ulteriore comodità: aprite le dashboard direttamente da un elenco
              nella panoramica. Assegnate semplicemente una o più righe alle dashboard e visualizzatele immediatamente
              nella panoramica, senza passare alla gestione delle dashboard. Potete passare rapidamente tra le dashboard
              e regolarne la rappresentazione temporale secondo necessità. Un aggiornamento pratico e user-friendly per
              la vostra analisi dati rapida ed efficiente!
            </ImgTextBox>
            <div className={styles.imgFlexWrapper}>
              <img src={Dashboard} className={styles.flexImage} alt="Screenshot Dashboard" />
            </div>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.darkWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}> Modelli di dashboard </div>
          <div className={styles.subcaption}>
            Le nuove modelli predefiniti semplificano la creazione dei dashboard! Crea i tuoi modelli per utilizzare i
            dashboard in modo ancora più personalizzato.
          </div>
          <div className={styles.flexWrapper}>
            <ImgTextBox
              imgSrc={GridVisTemplate}
              caption="Modelli predefiniti per la creazione di dashboard"
              altText="Modelli di GridVis"
              theme="light"
            >
              Con il nostro ultimo rilascio, vi offriamo una selezione di modelli predefiniti per rendere ancora più
              facile il processo di creazione delle dashboard. Utilizzateli come punto di partenza, personalizzateli
              secondo i vostri desideri o utilizzateli direttamente nei vostri progetti. Un modo efficace per
              risparmiare tempo e essere produttivi.
            </ImgTextBox>
            <ImgTextBox
              imgSrc={CustomTemplate}
              caption="Create le vostre proprie template per le dashboard "
              altText="Modelli personalizzati"
              theme="light"
            >
              Oltre ai modelli che forniamo, avete ora la possibilità di salvare le dashboard individuali come template.
              Ciò può essere fatto nelle impostazioni della dashboard. In questo modo, avete sempre una panoramica e
              potete avviare i vostri progetti ancora più velocemente.
            </ImgTextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapper}>
        <div className={styles.verticalSpace} />
        <div className={styles.sectionContent}>
          <div className={styles.caption}> Espansione per dashboard e report </div>
          <div className={styles.flexWrapper}>
            <div className={styles.textImgRow}>
              <TextBox caption="Analisi armonica precisa: Il nuovo oggetto PQ ">
                Il nuovo oggetto PQ &quot;Armoniche (Grafico a colonne)&quot; espande il portafoglio di oggetti di
                visualizzazione per dashboard e report in GridVis. Con questo strumento, le distorsioni armoniche
                storiche possono essere visualizzate in modo efficiente. L&apos;oggetto le presenta sotto forma di
                grafico a colonne, garantendo una panoramica chiara e descrittiva dei dati PQ importanti. Questo oggetto
                è un&apos;aggiunta significativa agli oggetti PQ esistenti e contribuisce a visualizzare meglio i
                parametri di qualità della potenza misurati.
              </TextBox>
              <img className={styles.flexImage} src={HarmonicsObject} alt="Armoniche dell'oggetto" />
            </div>
            <div className={styles.textImgRow}>
              <img className={styles.flexImage} src={BarchartObject} alt="grafico a barre" />
              <TextBox caption="Nuovo grafico a barre per una rapida panoramica">
                Un&apos;altra aggiunta nel campo della visualizzazione dei dati è il nuovo grafico a barre, che mostra
                le misurazioni in modo orizzontale. A differenza dei tradizionali grafici a colonne, questa
                rappresentazione orizzontale consente una comprensione più rapida dei dati, poiché i valori mostrati
                sono ordinati in modo decrescente. È ideale per applicazioni in cui è fondamentale una rapida
                comprensione e un&apos;analisi efficace dei dati. Con questo strumento, gli utenti possono analizzare i
                loro dati in modo più efficiente e prendere decisioni informate più rapidamente.
              </TextBox>
            </div>
            <TextBox caption="Scrivere l'indirizzo Modbus dalla dashboard ">
              Con il nostro nuovo oggetto &quot;Scrivere Modbus&quot;, potete scrivere parametri e stati direttamente
              dalla vostra dashboard a un indirizzo Modbus specificato. La particolarità è che potete non solo
              parametrizzare direttamente i dispositivi di misurazione, ma anche regolare dinamicamente i valori limite
              della corrente di dispersione o addirittura attivare uscite digitali. Tutto questo direttamente dalla
              vostra dashboard, offrendo un&apos;utile aggiunta al controllo e monitoraggio impianti. Ciò può avvenire
              tramite un campo di input manuale o con pulsanti predefiniti. Cliccare su un tale pulsante trasferisce
              automaticamente il valore impostato. Ciò apre una vasta gamma di applicazioni che prima non erano
              realizzabili.
            </TextBox>
            <TextBox caption="Regressione lineare multipla">
              Grazie al nuovo oggetto &quot;Regressione Lineare Multipla&quot;, potete ora condurre analisi ancora più
              approfondite. Questo strumento consente, ad esempio, di esaminare il consumo energetico rispetto a vari
              parametri per determinare se influenzano il consumo energetico. Le dipendenze identificate vengono
              presentate in modo chiaro in una tabella, garantendo sempre una visione completa.
            </TextBox>
          </div>
          <div className={styles.verticalSpace} />
        </div>
      </section>
      <section className={styles.lightWrapperMore}>
        <div className={styles.backdropReverse} />
        <div className={styles.sectionContent}>
          <div className={styles.caption} style={{ color: "#fff" }}>
            <strong>Ulteriori funzioni in breve</strong>
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className={styles.heading}>Rapporto di qualità della tensione ampliato secondo IEEE 519 </div>
              <p>
                Il rapporto sulla qualità della tensione in GridVis è stato aggiornato secondo la norma IEEE 519 - 2022.
                Questa esportazione dati, configurabile nell&apos;applicazione desktop di GridVis, consente, come
                consueto, una valutazione tramite il web. Una caratteristica di questo rapporto è la possibilità di
                generare analisi della corrente e della tensione indipendentemente l&apos;una dall&apos;altra, a seconda
                delle esigenze. Adattandosi alla norma IEEE 519 - 2022, l&apos;esportazione dati assicura una
                valutazione coerente e standardizzata della qualità della tensione, supportando un monitoraggio e
                controllo ottimali delle vostre strutture.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>Funzione di navigazione ottimizzata nel grafico a linee </div>
              <p>
                I nostri grafici a linee sulle dashboard offrono ora una navigazione migliorata con l&apos;introduzione
                del &quot;Navigatore&quot;. Questa funzione, che può essere attivata nelle impostazioni, vi consente di
                navigare in modo intuitivo attraverso i periodi temporali nella modalità presentazione della vostra
                dashboard. Ingrandite e riducete qualsiasi sezione per approfondire i vostri dati di misurazione e
                evidenziare dettagli importanti. Questa nuova funzione facilita il riconoscimento di schemi e deviazioni
                nei vostri dati e supporta un&apos;analisi efficiente.
              </p>
            </div>
            <div className={styles.column}>
              <div className={styles.heading}>
                Funzione di collegamento estesa per un utilizzo efficiente della dashboard
              </div>
              <p>
                La funzione di collegamento nelle nostre dashboard è stata estesa. Ad ogni collegamento può essere
                associato un dispositivo specifico, i cui dati verranno visualizzati all&apos;apertura della dashboard.
                Questa funzionalità apre la possibilità di passare direttamente da una panoramica di vari dispositivi a
                una visualizzazione dettagliata per un dispositivo di misurazione selezionato con poco sforzo. In questo
                modo, potete creare una dashboard che mostra costantemente dati di misurazione tipici di un dispositivo
                con un minimo sforzo.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.verticalSpace} />
      </section>
    </div>
  );
}
