const DEFAULT_CACHE_OUTDATED_IN_MS = 1000;
const CACHE = new Map();
export default function getCachedPromise(cacheKey, getPromise, config) {
    const cacheOutdatedInMs = (config === null || config === void 0 ? void 0 : config.cacheOutdatedInMs) || DEFAULT_CACHE_OUTDATED_IN_MS;
    const isCacheEnabled = (config === null || config === void 0 ? void 0 : config.isCacheEnabled) || cacheOutdatedInMs > 0;
    if (isCacheEnabled) {
        const cachedEntry = CACHE.get(cacheKey);
        const isOutdated = (date) => date.getTime() + cacheOutdatedInMs < new Date().getTime();
        if (cachedEntry && !isOutdated(cachedEntry.cachedAt))
            return cachedEntry.promise;
    }
    const promise = getPromise();
    if (isCacheEnabled) {
        CACHE.set(cacheKey, {
            promise,
            cachedAt: new Date(),
        });
    }
    return promise;
}
