import axios from "axios";
import getCachedPromise from "./getCachedPromise";
export default function sendPostRequest(uri, data, config) {
    const cacheKey = `POST ${uri}`;
    return getCachedPromise(cacheKey, () => {
        return axios.post(uri, data, Object.assign({ 
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { "Content-Type": "application/json" } }, config));
    }, { cacheOutdatedInMs: 1000 });
}
