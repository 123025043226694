import { defineMessages } from "react-intl";
export default defineMessages({
    landingpage: {
        id: "Websdk.container.navbar.entry.landingpage.title",
        defaultMessage: "What is new?",
        description: "[WebSDK Container] NavBar Title of the entry",
    },
    overview: {
        id: "Websdk.container.menu.entry.overview.title",
        defaultMessage: "Overview",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    dashboards: {
        id: "Websdk.container.menu.entry.dashboards.title",
        defaultMessage: "Dashboards",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    alarms: {
        id: "Websdk.container.menu.entry.alarms.title",
        defaultMessage: "Alarms",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    keyPerformanceAnalysis: {
        id: "Websdk.container.menu.entry.keyPerformanceAnalysis.title",
        defaultMessage: "Key Performance Analysis",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    measurementAnalysis: {
        id: "Websdk.container.menu.entry.measurementAnalysis.title",
        defaultMessage: "Measurement Analysis",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    eventBrowser: {
        id: "Websdk.container.menu.entry.eventBrowser.title",
        defaultMessage: "Event Browser",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    reportManager: {
        id: "Websdk.container.menu.entry.reports.title",
        defaultMessage: "Reports",
        description: "[WebSDK Container] Menu Title of the entry reports",
    },
    configurators: {
        id: "Websdk.container.menu.entry.configurators.title",
        defaultMessage: "Configurators",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    settings: {
        id: "Websdk.container.menu.entry.settings.title",
        defaultMessage: "Settings",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    administration: {
        id: "Websdk.container.menu.entry.administration.title",
        defaultMessage: "Administration",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    help: {
        id: "Websdk.container.menu.entry.help.title",
        defaultMessage: "Help",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    releaseNotes: {
        id: "Websdk.container.menu.entry.releaseNotes.title",
        defaultMessage: "Release Notes",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    license: {
        id: "Websdk.container.menu.entry.license.title",
        defaultMessage: "License",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    systemInfo: {
        id: "Websdk.container.menu.entry.systemInfo.title",
        defaultMessage: "System Info",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    // Sub-Menu Entries
    kpiManager: {
        id: "Websdk.container.menu.entry.kpiManager.title",
        defaultMessage: "KPI Manager",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    sankeyManager: {
        id: "Websdk.container.menu.entry.sankeyManager.title",
        defaultMessage: "Sankey Manager",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    vmpManager: {
        id: "Websdk.container.menu.entry.vmpManager.title",
        defaultMessage: "Virtual Measuring Points",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    userSettings: {
        id: "Websdk.container.menu.entry.userSettings.title",
        defaultMessage: "User Settings",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    system: {
        id: "Websdk.container.menu.entry.system.title",
        defaultMessage: "System",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    email: {
        id: "Websdk.container.menu.entry.email.title",
        defaultMessage: "E-Mail",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    masterData: {
        id: "Websdk.container.menu.entry.masterData.title",
        defaultMessage: "Master Data",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    userManagement: {
        id: "Websdk.container.menu.entry.userManagement.title",
        defaultMessage: "User Management",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    onlineRecorder: {
        id: "Websdk.container.menu.entry.onlineRecorder.title",
        defaultMessage: "Online Recorder",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    timeManagement: {
        id: "Websdk.container.menu.entry.timeManagement.title",
        defaultMessage: "Time Management",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    opcuaClient: {
        id: "Websdk.container.menu.entry.opcuaClient.title",
        defaultMessage: "OPC UA Client",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    pictureManager: {
        id: "Websdk.container.menu.entry.pictureManager.title",
        defaultMessage: "Picture Manager",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    deviceManager: {
        id: "Websdk.container.menu.entry.deviceManager.title",
        defaultMessage: "Device Manager",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    logicEditor: {
        id: "Websdk.container.menu.entry.logicEditor.title",
        defaultMessage: "Logic Editor",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    tutorials: {
        id: "Websdk.container.menu.entry.tutorials.title",
        defaultMessage: "Tutorials",
        description: "[WebSDK Container] Menu Title of the entry",
    },
    janipedia: {
        id: "Websdk.container.menu.entry.janipedia.title",
        defaultMessage: "Janipedia",
        description: "[WebSDK Container] Menu Title of the entry",
    },
});
