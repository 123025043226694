import { EProductEdition } from "../../enums";
export default function isProductEditionAtLeast(edition, currentEdition) {
    if (!currentEdition)
        return false;
    switch (edition) {
        case EProductEdition.ULTIMATE:
            return [EProductEdition.ULTIMATE].includes(currentEdition);
        case EProductEdition.SERVICE:
            return [EProductEdition.SERVICE, EProductEdition.ULTIMATE].includes(currentEdition);
        case EProductEdition.ENTERPRISE:
            return [EProductEdition.ULTIMATE, EProductEdition.SERVICE, EProductEdition.ENTERPRISE].includes(currentEdition);
        case EProductEdition.PROFESSIONAL:
            return [
                EProductEdition.ULTIMATE,
                EProductEdition.SERVICE,
                EProductEdition.ENTERPRISE,
                EProductEdition.PROFESSIONAL,
            ].includes(currentEdition);
        case EProductEdition.BASIC:
            return [
                EProductEdition.ULTIMATE,
                EProductEdition.SERVICE,
                EProductEdition.ENTERPRISE,
                EProductEdition.PROFESSIONAL,
                EProductEdition.BASIC,
            ].includes(currentEdition);
        default:
            throw new Error(`Unknown product edition '${JSON.stringify(edition)}'.`);
    }
}
