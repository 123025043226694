import { openAlarmsCount } from "../../../../helpers/apis";
export default function getContextData() {
    return openAlarmsCount().then((data) => {
        if (!data)
            return undefined;
        return {
            openAlarmsCount: data.openAlarmsCount,
        };
    });
}
