import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { EPriority, get as getFromLs, set as setInLs } from "@janitza/websdk-lib-localstorage";
import { setDesiredCountry, setDesiredLocale, setDesiredWeekStart } from "@janitza/websdk-lib-translations";
import UserContext from "./UserContext";
import { INVALIDATION_INTERVAL_MS, LOCALSTORAGE_KEY, LOCALSTORAGE_KEY_LAST_USERNAME, LOCALSTORAGE_LAST_SYNC_KEY, } from "./config";
import { forgotPassword as apiForgotPassword } from "../../helpers";
import { getContextDataFromLsOrApi, getMapFromRecord, useUpdateContextStateContiniously, writeToLocalstorage, } from "../helpers";
import { getContextData as getDataFromApi, login as apiLogin, logout as apiLogout, redirectAfterLogin, redirectAfterLogout, } from "./helpers";
import { REDIRECT_DELAY_AFTER_LOGIN_OR_LOGOUT_MS } from "../../config";
import { ProductContext } from "../ProductContext";
import { ProjectContext } from "../ProjectContext";
const refreshLastSyncDateInLs = () => window.localStorage.setItem(LOCALSTORAGE_LAST_SYNC_KEY, Date.now().toString());
export default function UserProvider({ children, onLoginSuccessfull, onLogoutSuccessfull, }) {
    const { locale } = useIntl();
    const { edition } = useContext(ProductContext);
    const { mainProject } = useContext(ProjectContext);
    const [user, setUser] = useState();
    const [lastUsername, setLastUsername] = useState(getFromLs(LOCALSTORAGE_KEY_LAST_USERNAME));
    const [isLoading, setIsLoading] = useState(true);
    const [isDefaultPasswordEnabled, setIsDefaultPasswordEnabled] = useState(false);
    const getUserFromSerializedUser = (serialized) => {
        return Object.assign(Object.assign({}, serialized), { permissions: getMapFromRecord(serialized.permissions) });
    };
    const isLoggedIn = useMemo(() => !!user, [user]);
    const updateLastUsername = useCallback((username) => {
        setInLs(LOCALSTORAGE_KEY_LAST_USERNAME, username, { priority: EPriority.HIGH });
        setLastUsername(username);
    }, []);
    const updateState = useCallback(() => {
        getContextDataFromLsOrApi(LOCALSTORAGE_KEY, getDataFromApi)
            .then((data) => {
            setIsLoading(false);
            if (data) {
                setIsDefaultPasswordEnabled(data.isDefaultPasswordEnabled);
                if (data.user) {
                    setUser((prev) => {
                        if (!data.user)
                            return prev;
                        const newState = Object.assign(Object.assign({}, data.user), { permissions: data.user.permissions });
                        // Custom equals to avoid unnecessary re-rendering due to object equality
                        const shouldUpdate = JSON.stringify(prev) !== JSON.stringify(newState);
                        if (shouldUpdate)
                            return newState;
                        return prev;
                    });
                    if (data.user.locale)
                        setDesiredLocale(data.user.locale);
                    if (data.user.country)
                        setDesiredCountry(data.user.country);
                    if (data.user.weekStart != null)
                        setDesiredWeekStart(data.user.weekStart);
                }
                else {
                    setUser(undefined);
                }
            }
            else {
                setUser(undefined);
                setIsDefaultPasswordEnabled(false);
            }
        })
            .catch(console.error);
    }, []);
    const invalidateLocalStorage = useCallback(() => writeToLocalstorage(LOCALSTORAGE_KEY, undefined), []);
    const login = useCallback((username, password) => apiLogin(username, password).then((data) => {
        invalidateLocalStorage();
        setIsDefaultPasswordEnabled(data.isDefaultPasswordEnabled);
        const loginUser = data.user;
        if (!loginUser)
            throw new Error("the user object received from API is undefined even though the login succeeded.");
        setUser(Object.assign(Object.assign({}, loginUser), { permissions: loginUser.permissions }));
        if (loginUser.locale)
            setDesiredLocale(loginUser.locale);
        if (loginUser.country)
            setDesiredCountry(loginUser.country);
        if (loginUser.weekStart != null)
            setDesiredWeekStart(loginUser.weekStart);
        updateLastUsername(username);
        refreshLastSyncDateInLs();
        if (onLoginSuccessfull)
            onLoginSuccessfull();
        setTimeout(() => {
            redirectAfterLogin(locale, edition, mainProject, getUserFromSerializedUser(loginUser));
        }, REDIRECT_DELAY_AFTER_LOGIN_OR_LOGOUT_MS);
    }), [edition, invalidateLocalStorage, locale, mainProject, onLoginSuccessfull, updateLastUsername]);
    const logout = useCallback(() => apiLogout().then(() => {
        setUser(undefined);
        invalidateLocalStorage();
        refreshLastSyncDateInLs();
        if (onLogoutSuccessfull)
            onLogoutSuccessfull();
        setTimeout(() => redirectAfterLogout(), REDIRECT_DELAY_AFTER_LOGIN_OR_LOGOUT_MS);
    }), [invalidateLocalStorage, onLogoutSuccessfull]);
    const isUserType = useCallback((userType) => (user ? user.type === userType : false), [user]);
    const hasPermission = useCallback((permissionId) => {
        const isPermissionGranted = user ? getMapFromRecord(user.permissions).get(permissionId) : undefined;
        if (user && !isPermissionGranted) {
            console.error(`The permission '${permissionId}' was not found.
          As developer you can add it to the whitelist in file SecurityPermissions.java`);
        }
        return isPermissionGranted || false;
    }, [user]);
    const sendPasswordResetMail = useCallback((email) => apiForgotPassword({ locale, email }), [locale]);
    useUpdateContextStateContiniously(updateState, LOCALSTORAGE_KEY, INVALIDATION_INTERVAL_MS);
    // To sync the login state across all open tabs in the browser without delay,
    // we listen for special keys inside localstorage. Whenever it changes, we invalidate it + refetch the state from API.
    const localStorageListener = useCallback((event) => {
        if (event.key === LOCALSTORAGE_LAST_SYNC_KEY)
            updateState();
    }, [updateState]);
    // Register the localstorage event-listener
    useEffect(() => window.addEventListener("storage", localStorageListener));
    const context = useMemo(() => {
        return {
            lastUsername,
            user: user ? getUserFromSerializedUser(user) : undefined,
            isLoggedIn,
            isDefaultPasswordEnabled,
            login,
            logout,
            isUserType,
            hasPermission,
            sendPasswordResetMail,
            isLoading,
        };
    }, [
        lastUsername,
        user,
        isLoggedIn,
        isDefaultPasswordEnabled,
        login,
        logout,
        isUserType,
        hasPermission,
        sendPasswordResetMail,
        isLoading,
    ]);
    return React.createElement(UserContext.Provider, { value: context }, children);
}
