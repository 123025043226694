import { defineMessages } from "react-intl";
export default defineMessages({
    forgotYourPassword: {
        id: "container.header.userPanel.forgotPasswordModal.header.label",
        defaultMessage: "Forgot your password?",
        description: "Forgot password dialog, table header label",
    },
    emailAddress: {
        id: "container.header.userPanel.forgotPasswordModal.email.label",
        defaultMessage: "Email address",
        description: "Forgot password dialog, email label left next to input field",
    },
    placeholderEmail: {
        id: "container.header.userPanel.forgotPasswordModal.email.input.placeholder",
        defaultMessage: "admin@yourdomain.com",
        description: "[WebSDK Container] Forgot password dialog, email input placeholder",
    },
    sendEmail: {
        id: "container.header.userPanel.forgotPasswordModal.buttons.send.text",
        defaultMessage: "Send Email",
        description: "[WebSDK Container] Forgot password dialog, button text for submitting the sending of the email",
    },
    abort: {
        id: "container.header.userPanel.forgotPasswordModal.buttons.close.text",
        defaultMessage: "Abort",
        description: "[WebSDK Container] Forgot password dialog, button text for closing the dialog",
    },
    forgotPwSuccessfulTitle: {
        id: "container.notification.forgotPw.successful.title",
        defaultMessage: "E-mail was sent successfully",
        description: "[WebSDK Container] Notification: Forgot password successful title",
    },
    forgotPwFailedTitle: {
        id: "container.notification.forgotPw.failed.title",
        defaultMessage: "Sending E-mail failed",
        description: "[WebSDK Container] Notification: Forgot password failed title",
    },
});
