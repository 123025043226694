import React from "react";
import classNames from "classnames";
import { CloseButton } from "@janitza/ui";
import styles from "./ModalWindow.module.scss";
export default function ModalWindow({ children, header, footer, onClose, className, contentClassName, style, dataCy = "ModalWindow", }) {
    const shouldShowHeader = !!header || !!onClose;
    return (React.createElement("div", { className: classNames(styles.window, className), style: style, "data-cy": dataCy },
        shouldShowHeader && (React.createElement("div", { className: styles.header },
            header && React.createElement("div", { className: styles.title }, header),
            onClose && (React.createElement("div", { className: styles.iconBar },
                React.createElement(CloseButton, { onClick: onClose }))))),
        React.createElement("div", { className: classNames(styles.content, contentClassName) }, children),
        footer && React.createElement("div", { className: styles.footer }, footer)));
}
