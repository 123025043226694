"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const websdk_lib_localstorage_1 = require("@janitza/websdk-lib-localstorage");
const constants_1 = require("../../constants");
/**
 * Returns the locale which is currently desired by the client.
 * However, the returned locale may or may not be supported by the framework.
 * To get the resulting and supported current locale, use getLocale() instead.
 */
function getDesiredLocale() {
    return (0, websdk_lib_localstorage_1.get)(constants_1.STORAGE_KEY_DESIRED_LOCALE);
}
exports.default = getDesiredLocale;
