import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./Logo.module.scss";
import { ProductContext } from "../../../../../contexts";
import { EProductEdition } from "../../../../../enums";
export default function Logo({ className, style }) {
    const { isInPreview, isEditionAtLeast } = useContext(ProductContext);
    const isAtLeastUltimate = isEditionAtLeast(EProductEdition.ULTIMATE);
    return (React.createElement("div", { className: classNames(styles.logo, isInPreview && styles.preview, isAtLeastUltimate && styles.energy, className), style: style }));
}
