import { getData, setData } from "./getterAndSetter";
export default function markVersionAsRead(version) {
    return getData()
        .then((result) => {
        if (result && result.version && result.version === version) {
            return true;
        }
        return setData({ version });
    })
        .catch((error) => {
        console.error(error);
        return false;
    });
}
