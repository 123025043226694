import { EProductEditionPremo } from "../../enums";
export default function isProductEditionPremoAtLeast(edition, currentEdition) {
    if (!currentEdition)
        return false;
    switch (edition) {
        case EProductEditionPremo.EXPERT:
            return [EProductEditionPremo.EXPERT].includes(currentEdition);
        case EProductEditionPremo.STANDARD:
            return [EProductEditionPremo.EXPERT, EProductEditionPremo.STANDARD].includes(currentEdition);
        case EProductEditionPremo.ESSENTIALS:
            return [EProductEditionPremo.ESSENTIALS, EProductEditionPremo.STANDARD, EProductEditionPremo.EXPERT].includes(currentEdition);
        case EProductEditionPremo.HUB:
            return [
                EProductEditionPremo.ESSENTIALS,
                EProductEditionPremo.STANDARD,
                EProductEditionPremo.EXPERT,
                EProductEditionPremo.HUB,
            ].includes(currentEdition);
        case EProductEditionPremo.NOT_LICENSED:
            return false;
        default:
            throw new Error(`Unknown product edition '${JSON.stringify(edition)}'.`);
    }
}
