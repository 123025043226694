import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import styles from "./MenuEntry.module.scss";
import menuStyle from "../../Menu.module.scss"; // TODO: SRP
import { ModalsContext } from "../../../../../../../contexts";
import { ModalWindow } from "../../../../../../modalWindows";
export default function MenuEntry({ entry, onClose, className, style }) {
    const { name, iconSrc, href, target, onClick, children } = entry;
    const { push: pushModal, pop: popModal } = useContext(ModalsContext);
    const handleOnClick = useCallback((event) => {
        if (onClick) {
            event.preventDefault();
            onClick(event);
        }
        else if (target === "_modal") {
            event.preventDefault();
            pushModal({
                content: (React.createElement(ModalWindow, { header: name, onClose: () => popModal() },
                    React.createElement("iframe", { className: styles.iframe, src: href || "" }))),
            });
        }
        if (onClose && (!children || children.length === 0))
            onClose(event);
    }, [children, href, name, onClick, onClose, popModal, pushModal, target]);
    return (React.createElement("li", { className: classNames(styles.menuEntry, className), style: style },
        React.createElement("a", { href: href, target: target, onClick: handleOnClick, className: "qf-ListItem" },
            name,
            iconSrc && React.createElement("img", { src: iconSrc, alt: "" })),
        children && (React.createElement("ul", { className: classNames("qf-List"), "data-cy": name },
            React.createElement("li", { className: menuStyle.menuHeader }, name),
            children
                .filter((child) => child.isShown)
                .map((child) => (React.createElement(MenuEntry, { key: child.id, entry: child, onClose: onClose })))))));
}
