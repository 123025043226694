import { EPriority, set as setInLs } from "@janitza/websdk-lib-localstorage";
import { logout as apiLogout } from "../../../../helpers/apis";
import { LOCALSTORAGE_KEY_DEPRECATED } from "../../config";
export default function logout() {
    return apiLogout().then((response) => {
        setInLs(LOCALSTORAGE_KEY_DEPRECATED, undefined, { priority: EPriority.HIGH });
        if (!response)
            return false;
        if (response.loggedIn)
            return false;
        return true;
    });
}
